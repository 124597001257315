/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        Action,
        CreateUsagePackageCatalogue,
        CreateUsagePackageType,
        UpdateUsagePackageCatalogue,
        UsagePackageType,
        UsagePackageTypePage,
    } from '../models';
        import {
            ActionFromJSON,
            ActionToJSON,
            CreateUsagePackageCatalogueFromJSON,
            CreateUsagePackageCatalogueToJSON,
            CreateUsagePackageTypeFromJSON,
            CreateUsagePackageTypeToJSON,
            UpdateUsagePackageCatalogueFromJSON,
            UpdateUsagePackageCatalogueToJSON,
            UsagePackageTypeFromJSON,
            UsagePackageTypeToJSON,
            UsagePackageTypePageFromJSON,
            UsagePackageTypePageToJSON,
        } from '../models';

            export interface UsagePackageApiCreateCustomerUsagePackageTypeRequest {
                customerId: string;
                createUsagePackageCatalogue: CreateUsagePackageCatalogue;
            }

            export interface UsagePackageApiCreateUsagePackageTypeRequest {
                createUsagePackageType: CreateUsagePackageType;
            }

            export interface UsagePackageApiDeleteUsagePackageTypeRequest {
                usagePackageTypeId: string;
            }

            export interface UsagePackageApiGetCustomerUsagePackageTypeRequest {
                customerId: string;
                usagePackageTypeId: string;
            }

            export interface UsagePackageApiGetCustomerUsagePackageTypesRequest {
                customerId: string;
                pageNumber: number;
                pageSize: number;
                includeDeleted?: boolean | null;
                search?: string;
                country?: string;
                dataPackageEnabled?: boolean | null;
                smsPackageEnabled?: boolean | null;
                usagePackageTypeAssigned?: boolean | null;
            }

            export interface UsagePackageApiGetUsagePackageTypeRequest {
                usagePackageTypeId: string;
            }

            export interface UsagePackageApiGetUsagePackageTypesRequest {
                pageNumber: number;
                pageSize: number;
                includeDeleted?: boolean | null;
                search?: string;
                country?: string;
                dataPackageEnabled?: boolean | null;
                smsPackageEnabled?: boolean | null;
                usagePackageTypeAssigned?: boolean | null;
                usagePackageTypeAssignedToCatalogue?: boolean | null;
            }

            export interface UsagePackageApiUpdateCustomerUsagePackageTypeInformationRequest {
                customerId: string;
                usagePackageTypeId: string;
                updateUsagePackageCatalogue: UpdateUsagePackageCatalogue;
            }

        /**
        * 
        */
            export class UsagePackageApi extends runtime.BaseAPI {

            /**
                * Create customer usage package type catalogue entry
            */
            async createCustomerUsagePackageTypeRaw(requestParameters: UsagePackageApiCreateCustomerUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
                    throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling createCustomerUsagePackageType.');
                    }

                    if (requestParameters.createUsagePackageCatalogue === null || requestParameters.createUsagePackageCatalogue === undefined) {
                    throw new runtime.RequiredError('createUsagePackageCatalogue','Required parameter requestParameters.createUsagePackageCatalogue was null or undefined when calling createCustomerUsagePackageType.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers/{customerId}/usagepackagetypes`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: CreateUsagePackageCatalogueToJSON(requestParameters.createUsagePackageCatalogue),
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Create customer usage package type catalogue entry
            */
                async createCustomerUsagePackageType(requestParameters: UsagePackageApiCreateCustomerUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.createCustomerUsagePackageTypeRaw(requestParameters, initOverrides);
                }

            /**
                * Create a new usage package type. The id assigned to the usage package type will be the id of the items related to the action. 
                * Create usage package type
            */
            async createUsagePackageTypeRaw(requestParameters: UsagePackageApiCreateUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Action>>> {
                    if (requestParameters.createUsagePackageType === null || requestParameters.createUsagePackageType === undefined) {
                    throw new runtime.RequiredError('createUsagePackageType','Required parameter requestParameters.createUsagePackageType was null or undefined when calling createUsagePackageType.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/usagepackagetypes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: CreateUsagePackageTypeToJSON(requestParameters.createUsagePackageType),
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
            }

            /**
                * Create a new usage package type. The id assigned to the usage package type will be the id of the items related to the action. 
                * Create usage package type
            */
                async createUsagePackageType(requestParameters: UsagePackageApiCreateUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Action>> {
                    const response = await this.createUsagePackageTypeRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Marks a usage package type for soft deletion. Once deleted it can no longer be assigned to new SIM cards. The assigned usage packages will continue to function, unless revoked from the assigned SIM cards.
                * Delete usage package type
            */
            async deleteUsagePackageTypeRaw(requestParameters: UsagePackageApiDeleteUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.usagePackageTypeId === null || requestParameters.usagePackageTypeId === undefined) {
                    throw new runtime.RequiredError('usagePackageTypeId','Required parameter requestParameters.usagePackageTypeId was null or undefined when calling deleteUsagePackageType.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/usagepackagetypes/{usagePackageTypeId}`.replace(`{${"usagePackageTypeId"}}`, encodeURIComponent(String(requestParameters.usagePackageTypeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Marks a usage package type for soft deletion. Once deleted it can no longer be assigned to new SIM cards. The assigned usage packages will continue to function, unless revoked from the assigned SIM cards.
                * Delete usage package type
            */
                async deleteUsagePackageType(requestParameters: UsagePackageApiDeleteUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.deleteUsagePackageTypeRaw(requestParameters, initOverrides);
                }

            /**
                * Get a single customer usage package type catalogue entry.
                * Get customer usage package type catalogue entry
            */
            async getCustomerUsagePackageTypeRaw(requestParameters: UsagePackageApiGetCustomerUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsagePackageType>> {
                    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
                    throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomerUsagePackageType.');
                    }

                    if (requestParameters.usagePackageTypeId === null || requestParameters.usagePackageTypeId === undefined) {
                    throw new runtime.RequiredError('usagePackageTypeId','Required parameter requestParameters.usagePackageTypeId was null or undefined when calling getCustomerUsagePackageType.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers/{customerId}/usagepackagetypes/{usagePackageTypeId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"usagePackageTypeId"}}`, encodeURIComponent(String(requestParameters.usagePackageTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => UsagePackageTypeFromJSON(jsonValue));
            }

            /**
                * Get a single customer usage package type catalogue entry.
                * Get customer usage package type catalogue entry
            */
                async getCustomerUsagePackageType(requestParameters: UsagePackageApiGetCustomerUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsagePackageType> {
                    const response = await this.getCustomerUsagePackageTypeRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get customer usage package type catalogue
            */
            async getCustomerUsagePackageTypesRaw(requestParameters: UsagePackageApiGetCustomerUsagePackageTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsagePackageTypePage>> {
                    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
                    throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomerUsagePackageTypes.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getCustomerUsagePackageTypes.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getCustomerUsagePackageTypes.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.includeDeleted !== undefined) {
                            queryParameters['includeDeleted'] = requestParameters.includeDeleted;
                    }

                    if (requestParameters.search !== undefined) {
                            queryParameters['search'] = requestParameters.search;
                    }

                    if (requestParameters.country !== undefined) {
                            queryParameters['country'] = requestParameters.country;
                    }

                    if (requestParameters.dataPackageEnabled !== undefined) {
                            queryParameters['dataPackageEnabled'] = requestParameters.dataPackageEnabled;
                    }

                    if (requestParameters.smsPackageEnabled !== undefined) {
                            queryParameters['smsPackageEnabled'] = requestParameters.smsPackageEnabled;
                    }

                    if (requestParameters.usagePackageTypeAssigned !== undefined) {
                            queryParameters['usagePackageTypeAssigned'] = requestParameters.usagePackageTypeAssigned;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers/{customerId}/usagepackagetypes`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => UsagePackageTypePageFromJSON(jsonValue));
            }

            /**
                * Get customer usage package type catalogue
            */
                async getCustomerUsagePackageTypes(requestParameters: UsagePackageApiGetCustomerUsagePackageTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsagePackageTypePage> {
                    const response = await this.getCustomerUsagePackageTypesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get a single usage package type.
                * Get usage package type
            */
            async getUsagePackageTypeRaw(requestParameters: UsagePackageApiGetUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsagePackageType>> {
                    if (requestParameters.usagePackageTypeId === null || requestParameters.usagePackageTypeId === undefined) {
                    throw new runtime.RequiredError('usagePackageTypeId','Required parameter requestParameters.usagePackageTypeId was null or undefined when calling getUsagePackageType.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/usagepackagetypes/{usagePackageTypeId}`.replace(`{${"usagePackageTypeId"}}`, encodeURIComponent(String(requestParameters.usagePackageTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => UsagePackageTypeFromJSON(jsonValue));
            }

            /**
                * Get a single usage package type.
                * Get usage package type
            */
                async getUsagePackageType(requestParameters: UsagePackageApiGetUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsagePackageType> {
                    const response = await this.getUsagePackageTypeRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all available usage package types.
                * Get usage package types
            */
            async getUsagePackageTypesRaw(requestParameters: UsagePackageApiGetUsagePackageTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsagePackageTypePage>> {
                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getUsagePackageTypes.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getUsagePackageTypes.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.includeDeleted !== undefined) {
                            queryParameters['includeDeleted'] = requestParameters.includeDeleted;
                    }

                    if (requestParameters.search !== undefined) {
                            queryParameters['search'] = requestParameters.search;
                    }

                    if (requestParameters.country !== undefined) {
                            queryParameters['country'] = requestParameters.country;
                    }

                    if (requestParameters.dataPackageEnabled !== undefined) {
                            queryParameters['dataPackageEnabled'] = requestParameters.dataPackageEnabled;
                    }

                    if (requestParameters.smsPackageEnabled !== undefined) {
                            queryParameters['smsPackageEnabled'] = requestParameters.smsPackageEnabled;
                    }

                    if (requestParameters.usagePackageTypeAssigned !== undefined) {
                            queryParameters['usagePackageTypeAssigned'] = requestParameters.usagePackageTypeAssigned;
                    }

                    if (requestParameters.usagePackageTypeAssignedToCatalogue !== undefined) {
                            queryParameters['usagePackageTypeAssignedToCatalogue'] = requestParameters.usagePackageTypeAssignedToCatalogue;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/usagepackagetypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => UsagePackageTypePageFromJSON(jsonValue));
            }

            /**
                * Get all available usage package types.
                * Get usage package types
            */
                async getUsagePackageTypes(requestParameters: UsagePackageApiGetUsagePackageTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsagePackageTypePage> {
                    const response = await this.getUsagePackageTypesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Update name and description for catalogue entry.
                * Update customer usage package type catalogue information
            */
            async updateCustomerUsagePackageTypeInformationRaw(requestParameters: UsagePackageApiUpdateCustomerUsagePackageTypeInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsagePackageType>> {
                    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
                    throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updateCustomerUsagePackageTypeInformation.');
                    }

                    if (requestParameters.usagePackageTypeId === null || requestParameters.usagePackageTypeId === undefined) {
                    throw new runtime.RequiredError('usagePackageTypeId','Required parameter requestParameters.usagePackageTypeId was null or undefined when calling updateCustomerUsagePackageTypeInformation.');
                    }

                    if (requestParameters.updateUsagePackageCatalogue === null || requestParameters.updateUsagePackageCatalogue === undefined) {
                    throw new runtime.RequiredError('updateUsagePackageCatalogue','Required parameter requestParameters.updateUsagePackageCatalogue was null or undefined when calling updateCustomerUsagePackageTypeInformation.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/customers/{customerId}/usagepackagetypes/{usagePackageTypeId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"usagePackageTypeId"}}`, encodeURIComponent(String(requestParameters.usagePackageTypeId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
                                body: UpdateUsagePackageCatalogueToJSON(requestParameters.updateUsagePackageCatalogue),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => UsagePackageTypeFromJSON(jsonValue));
            }

            /**
                * Update name and description for catalogue entry.
                * Update customer usage package type catalogue information
            */
                async updateCustomerUsagePackageTypeInformation(requestParameters: UsagePackageApiUpdateCustomerUsagePackageTypeInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsagePackageType> {
                    const response = await this.updateCustomerUsagePackageTypeInformationRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
