/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        CdrState,
        DataCdr,
        DataCdrPage,
        SmsCdr,
        SmsCdrPage,
    } from '../models';
        import {
            CdrStateFromJSON,
            CdrStateToJSON,
            DataCdrFromJSON,
            DataCdrToJSON,
            DataCdrPageFromJSON,
            DataCdrPageToJSON,
            SmsCdrFromJSON,
            SmsCdrToJSON,
            SmsCdrPageFromJSON,
            SmsCdrPageToJSON,
        } from '../models';

            export interface CdrApiGetDataCdrRequest {
                pageSize: number;
                pageNumber: number;
                fromDate: Date;
                toDate: Date;
                customerId?: string;
                billingGroupId?: string;
                icc?: string;
                status?: CdrState;
                plmn?: string;
                countryCode?: string;
            }

            export interface CdrApiGetDataCdrRecordRequest {
                cdrId: string;
            }

            export interface CdrApiGetSmsCdrRequest {
                pageSize: number;
                pageNumber: number;
                fromDate: Date;
                toDate: Date;
                customerId?: string;
                billingGroupId?: string;
                icc?: string;
                status?: CdrState;
                plmn?: string;
                countryCode?: string;
            }

            export interface CdrApiGetSmsCdrRecordRequest {
                cdrId: string;
            }

        /**
        * 
        */
            export class CdrApi extends runtime.BaseAPI {

            /**
                * Fetch data CDR.  All CDR records will have a status representing whether it could be rated or not, in case it could not be rated, it will not be possible to invoice the customer. The results will be sorted newest to oldest.
            */
            async getDataCdrRaw(requestParameters: CdrApiGetDataCdrRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataCdrPage>> {
                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getDataCdr.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getDataCdr.');
                    }

                    if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
                    throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling getDataCdr.');
                    }

                    if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
                    throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling getDataCdr.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.customerId !== undefined) {
                            queryParameters['customerId'] = requestParameters.customerId;
                    }

                    if (requestParameters.billingGroupId !== undefined) {
                            queryParameters['billingGroupId'] = requestParameters.billingGroupId;
                    }

                    if (requestParameters.icc !== undefined) {
                            queryParameters['icc'] = requestParameters.icc;
                    }

                    if (requestParameters.fromDate !== undefined) {
                            queryParameters['fromDate'] = (requestParameters.fromDate as any).toISOString().substr(0,10);
                    }

                    if (requestParameters.toDate !== undefined) {
                            queryParameters['toDate'] = (requestParameters.toDate as any).toISOString().substr(0,10);
                    }

                    if (requestParameters.status !== undefined) {
                            queryParameters['status'] = requestParameters.status;
                    }

                    if (requestParameters.plmn !== undefined) {
                            queryParameters['plmn'] = requestParameters.plmn;
                    }

                    if (requestParameters.countryCode !== undefined) {
                            queryParameters['countryCode'] = requestParameters.countryCode;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/cdr/data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => DataCdrPageFromJSON(jsonValue));
            }

            /**
                * Fetch data CDR.  All CDR records will have a status representing whether it could be rated or not, in case it could not be rated, it will not be possible to invoice the customer. The results will be sorted newest to oldest.
            */
                async getDataCdr(requestParameters: CdrApiGetDataCdrRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataCdrPage> {
                    const response = await this.getDataCdrRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Retrieve a single Data CDR record
            */
            async getDataCdrRecordRaw(requestParameters: CdrApiGetDataCdrRecordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataCdr>> {
                    if (requestParameters.cdrId === null || requestParameters.cdrId === undefined) {
                    throw new runtime.RequiredError('cdrId','Required parameter requestParameters.cdrId was null or undefined when calling getDataCdrRecord.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/cdr/data/{cdrId}`.replace(`{${"cdrId"}}`, encodeURIComponent(String(requestParameters.cdrId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => DataCdrFromJSON(jsonValue));
            }

            /**
                * Retrieve a single Data CDR record
            */
                async getDataCdrRecord(requestParameters: CdrApiGetDataCdrRecordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataCdr> {
                    const response = await this.getDataCdrRecordRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetch SMS CDR.  All CDR records will have a status representing whether it could be rated or not, in case it could not be rated, it will not be possible to invoice the customer. The results will be sorted newest to oldest.
            */
            async getSmsCdrRaw(requestParameters: CdrApiGetSmsCdrRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmsCdrPage>> {
                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getSmsCdr.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getSmsCdr.');
                    }

                    if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
                    throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling getSmsCdr.');
                    }

                    if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
                    throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling getSmsCdr.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.customerId !== undefined) {
                            queryParameters['customerId'] = requestParameters.customerId;
                    }

                    if (requestParameters.billingGroupId !== undefined) {
                            queryParameters['billingGroupId'] = requestParameters.billingGroupId;
                    }

                    if (requestParameters.icc !== undefined) {
                            queryParameters['icc'] = requestParameters.icc;
                    }

                    if (requestParameters.fromDate !== undefined) {
                            queryParameters['fromDate'] = (requestParameters.fromDate as any).toISOString().substr(0,10);
                    }

                    if (requestParameters.toDate !== undefined) {
                            queryParameters['toDate'] = (requestParameters.toDate as any).toISOString().substr(0,10);
                    }

                    if (requestParameters.status !== undefined) {
                            queryParameters['status'] = requestParameters.status;
                    }

                    if (requestParameters.plmn !== undefined) {
                            queryParameters['plmn'] = requestParameters.plmn;
                    }

                    if (requestParameters.countryCode !== undefined) {
                            queryParameters['countryCode'] = requestParameters.countryCode;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/cdr/sms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SmsCdrPageFromJSON(jsonValue));
            }

            /**
                * Fetch SMS CDR.  All CDR records will have a status representing whether it could be rated or not, in case it could not be rated, it will not be possible to invoice the customer. The results will be sorted newest to oldest.
            */
                async getSmsCdr(requestParameters: CdrApiGetSmsCdrRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmsCdrPage> {
                    const response = await this.getSmsCdrRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Retrieve a single SMS CDR record
            */
            async getSmsCdrRecordRaw(requestParameters: CdrApiGetSmsCdrRecordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmsCdr>> {
                    if (requestParameters.cdrId === null || requestParameters.cdrId === undefined) {
                    throw new runtime.RequiredError('cdrId','Required parameter requestParameters.cdrId was null or undefined when calling getSmsCdrRecord.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/cdr/sms/{cdrId}`.replace(`{${"cdrId"}}`, encodeURIComponent(String(requestParameters.cdrId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SmsCdrFromJSON(jsonValue));
            }

            /**
                * Retrieve a single SMS CDR record
            */
                async getSmsCdrRecord(requestParameters: CdrApiGetSmsCdrRecordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmsCdr> {
                    const response = await this.getSmsCdrRecordRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
