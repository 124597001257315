/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateNetworkAccessCost } from './CreateNetworkAccessCost';
import {
    CreateNetworkAccessCostFromJSON,
    CreateNetworkAccessCostFromJSONTyped,
    CreateNetworkAccessCostToJSON,
} from './CreateNetworkAccessCost';
import type { CreateTelcoCost } from './CreateTelcoCost';
import {
    CreateTelcoCostFromJSON,
    CreateTelcoCostFromJSONTyped,
    CreateTelcoCostToJSON,
} from './CreateTelcoCost';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { YearMonth } from './YearMonth';
import {
    YearMonthFromJSON,
    YearMonthFromJSONTyped,
    YearMonthToJSON,
} from './YearMonth';

/**
 * 
 * @export
 * @interface CreateCostPlan
 */
export interface CreateCostPlan {
    /**
     * 
     * @type {string}
     * @memberof CreateCostPlan
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCostPlan
     */
    description?: string;
    /**
     * 
     * @type {YearMonth}
     * @memberof CreateCostPlan
     */
    validAt: YearMonth;
    /**
     * 
     * @type {number}
     * @memberof CreateCostPlan
     */
    conversionFactor: number;
    /**
     * 
     * @type {Currency}
     * @memberof CreateCostPlan
     */
    currency: Currency;
    /**
     * 
     * @type {Array<CreateTelcoCost>}
     * @memberof CreateCostPlan
     */
    telcoCosts: Array<CreateTelcoCost>;
    /**
     * 
     * @type {Array<CreateNetworkAccessCost>}
     * @memberof CreateCostPlan
     */
    networkAccessCosts: Array<CreateNetworkAccessCost>;
}

/**
 * Check if a given object implements the CreateCostPlan interface.
 */
export function instanceOfCreateCostPlan(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "validAt" in value;
    isInstance = isInstance && "conversionFactor" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "telcoCosts" in value;
    isInstance = isInstance && "networkAccessCosts" in value;

    return isInstance;
}

export function CreateCostPlanFromJSON(json: any): CreateCostPlan {
    return CreateCostPlanFromJSONTyped(json, false);
}

export function CreateCostPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCostPlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'validAt': YearMonthFromJSON(json['validAt']),
        'conversionFactor': json['conversionFactor'],
        'currency': CurrencyFromJSON(json['currency']),
        'telcoCosts': ((json['telcoCosts'] as Array<any>).map(CreateTelcoCostFromJSON)),
        'networkAccessCosts': ((json['networkAccessCosts'] as Array<any>).map(CreateNetworkAccessCostFromJSON)),
    };
}

export function CreateCostPlanToJSON(value?: CreateCostPlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'validAt': YearMonthToJSON(value.validAt),
        'conversionFactor': value.conversionFactor,
        'currency': CurrencyToJSON(value.currency),
        'telcoCosts': ((value.telcoCosts as Array<any>).map(CreateTelcoCostToJSON)),
        'networkAccessCosts': ((value.networkAccessCosts as Array<any>).map(CreateNetworkAccessCostToJSON)),
    };
}

