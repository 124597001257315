/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CdrState } from './CdrState';
import {
    CdrStateFromJSON,
    CdrStateFromJSONTyped,
    CdrStateToJSON,
} from './CdrState';

/**
 * 
 * @export
 * @interface DataCdr
 */
export interface DataCdr {
    /**
     * 
     * @type {string}
     * @memberof DataCdr
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DataCdr
     */
    sessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof DataCdr
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof DataCdr
     */
    billingGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof DataCdr
     */
    icc: string;
    /**
     * 
     * @type {string}
     * @memberof DataCdr
     */
    imsi: string;
    /**
     * 
     * @type {string}
     * @memberof DataCdr
     */
    networkCredentialTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof DataCdr
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof DataCdr
     */
    simConfigurationId?: string;
    /**
     * 
     * @type {string}
     * @memberof DataCdr
     */
    costPlanId?: string;
    /**
     * 
     * @type {string}
     * @memberof DataCdr
     */
    ratePlanId?: string;
    /**
     * Cost per byte before applying any markup
     * @type {number}
     * @memberof DataCdr
     */
    unitCost?: number;
    /**
     * The total cost of the data CDR entry, before applying any markups
     * @type {number}
     * @memberof DataCdr
     */
    cost?: number;
    /**
     * 
     * @type {number}
     * @memberof DataCdr
     */
    bytes?: number;
    /**
     * 
     * @type {number}
     * @memberof DataCdr
     */
    billableBytes: number;
    /**
     * 
     * @type {string}
     * @memberof DataCdr
     */
    plmn: string;
    /**
     * 
     * @type {string}
     * @memberof DataCdr
     */
    countryCode: string;
    /**
     * 
     * @type {Date}
     * @memberof DataCdr
     */
    openedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DataCdr
     */
    closedAt?: Date;
    /**
     * The time at which the CDR was rated for, relevant for which invoice this CDR will be applicable for.
     * @type {Date}
     * @memberof DataCdr
     */
    recordedAt: Date;
    /**
     * 
     * @type {CdrState}
     * @memberof DataCdr
     */
    status?: CdrState;
}

/**
 * Check if a given object implements the DataCdr interface.
 */
export function instanceOfDataCdr(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "icc" in value;
    isInstance = isInstance && "imsi" in value;
    isInstance = isInstance && "billableBytes" in value;
    isInstance = isInstance && "plmn" in value;
    isInstance = isInstance && "countryCode" in value;
    isInstance = isInstance && "recordedAt" in value;

    return isInstance;
}

export function DataCdrFromJSON(json: any): DataCdr {
    return DataCdrFromJSONTyped(json, false);
}

export function DataCdrFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataCdr {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sessionId': !exists(json, 'sessionId') ? undefined : json['sessionId'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'billingGroupId': !exists(json, 'billingGroupId') ? undefined : json['billingGroupId'],
        'icc': json['icc'],
        'imsi': json['imsi'],
        'networkCredentialTypeId': !exists(json, 'networkCredentialTypeId') ? undefined : json['networkCredentialTypeId'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'simConfigurationId': !exists(json, 'simConfigurationId') ? undefined : json['simConfigurationId'],
        'costPlanId': !exists(json, 'costPlanId') ? undefined : json['costPlanId'],
        'ratePlanId': !exists(json, 'ratePlanId') ? undefined : json['ratePlanId'],
        'unitCost': !exists(json, 'unitCost') ? undefined : json['unitCost'],
        'cost': !exists(json, 'cost') ? undefined : json['cost'],
        'bytes': !exists(json, 'bytes') ? undefined : json['bytes'],
        'billableBytes': json['billableBytes'],
        'plmn': json['plmn'],
        'countryCode': json['countryCode'],
        'openedAt': !exists(json, 'openedAt') ? undefined : (new Date(json['openedAt'])),
        'closedAt': !exists(json, 'closedAt') ? undefined : (new Date(json['closedAt'])),
        'recordedAt': (new Date(json['recordedAt'])),
        'status': !exists(json, 'status') ? undefined : CdrStateFromJSON(json['status']),
    };
}

export function DataCdrToJSON(value?: DataCdr | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sessionId': value.sessionId,
        'customerId': value.customerId,
        'billingGroupId': value.billingGroupId,
        'icc': value.icc,
        'imsi': value.imsi,
        'networkCredentialTypeId': value.networkCredentialTypeId,
        'productId': value.productId,
        'simConfigurationId': value.simConfigurationId,
        'costPlanId': value.costPlanId,
        'ratePlanId': value.ratePlanId,
        'unitCost': value.unitCost,
        'cost': value.cost,
        'bytes': value.bytes,
        'billableBytes': value.billableBytes,
        'plmn': value.plmn,
        'countryCode': value.countryCode,
        'openedAt': value.openedAt === undefined ? undefined : (value.openedAt.toISOString()),
        'closedAt': value.closedAt === undefined ? undefined : (value.closedAt.toISOString()),
        'recordedAt': (value.recordedAt.toISOString()),
        'status': CdrStateToJSON(value.status),
    };
}

