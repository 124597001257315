import React from "react";
import { SearchSelectInput, SearchSingleInputProps, SearchStateConfig } from "@cobira/ui-library";
import { CdrState } from "../../api";
import { CdrStateLabels } from "../../labels/CdrStateLabels";

export type CdrStateSelect = {
    value: CdrState;
    label: string;
};

export interface SearchSelectCdrStateInputProps<
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
> extends Omit<SearchSingleInputProps<CdrStateSelect, K, TSearchStateConfig>, "label"> {}

export const SearchSelectCdrStateInput = <
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
>({
    registration,
    placeholder = "CDR State",
}: SearchSelectCdrStateInputProps<K, TSearchStateConfig>) => {
    const items: CdrStateSelect[] = [
        {
            value: CdrState.Calculated,
            label: CdrStateLabels[CdrState.Calculated],
        },
        {
            value: CdrState.ErrorRaised,
            label: CdrStateLabels[CdrState.ErrorRaised],
        },
        {
            value: CdrState.Unknown,
            label: CdrStateLabels[CdrState.Unknown],
        },
    ];
    return (
        <SearchSelectInput
            registration={registration}
            items={items}
            placeholder={placeholder}
            autocompleteAbleMapping={state => state.label}
            displayMapping={state => state.label}
        />
    );
};
