import React from "react";
import { LoadingSkeleton, PlaceholderText, QueryCell } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";

export interface SimConfigurationCellProps {
    simConfigurationId?: string;
}

export const SimConfigurationCell = ({ simConfigurationId }: SimConfigurationCellProps) => {
    const { productApi } = useApi();
    if (!simConfigurationId) {
        return <PlaceholderText text={null} />;
    }

    return (
        <QueryCell
            loadingComponent={<LoadingSkeleton />}
            query={{
                queryFn: () => productApi.getSimConfiguration({ simConfigurationId: simConfigurationId }),
                queryKey: ["simconfigurations", simConfigurationId],
            }}
            render={result => <PlaceholderText text={result?.data?.name} />}
        />
    );
};
