import React from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { CostPlan, CostPlanPage } from "../../api";
import { createColumnHelper } from "@tanstack/react-table";
import { CobiraTable, DateTime, Month, monthToInt, PlaceholderText } from "@cobira/ui-library";
import { CurrencyLabels } from "../../labels/CurrencyLabels";
import {
    CobiraTableManualPaginationProps,
    CobiraTableRowClickEnabledProps,
} from "@cobira/ui-library/dist/cjs/components/CobiraTable/CobiraTable";

const costPlanColumn = createColumnHelper<CostPlan>();
const COLUMNS = [
    costPlanColumn.accessor("name", {
        id: "name",
        header: "Name",
        cell: row => row.getValue(),
    }),
    costPlanColumn.accessor("description", {
        id: "description",
        header: "Description",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    costPlanColumn.accessor("currency", {
        id: "currency",
        header: "Currency",
        cell: row => CurrencyLabels[row.getValue()],
    }),
    costPlanColumn.accessor("overwritable", {
        id: "overwritable",
        header: "Locked for edits",
        cell: row => (row.getValue() ? "yes" : "no"),
    }),
    costPlanColumn.accessor("validAt", {
        id: "validAit",
        header: "Applies From",
        cell: row => (
            <DateTime
                value={new Date(row.getValue().year, monthToInt(row.getValue().month as Month))}
                format={"MMMM, yyyy"}
            />
        ),
    }),
];

export interface CostPlanTableProps {
    costPlanQuery: UseQueryResult<CostPlanPage>;
    pagination: CobiraTableManualPaginationProps;
    withRowClick: CobiraTableRowClickEnabledProps<CostPlan>;
}

const CostPlanTable = ({ costPlanQuery, pagination, withRowClick }: CostPlanTableProps) => {
    const { data: costPlans, isLoading: isLoadingCostPlans } = costPlanQuery;
    return (
        <CobiraTable
            columns={COLUMNS}
            data={costPlans?.content || []}
            isLoading={isLoadingCostPlans}
            withPagination={pagination}
            withRowClick={withRowClick}
        />
    );
};

export default CostPlanTable;
