import { useParams } from "react-router";
import { PageBackButton, PageLayout, PageTitle } from "@cobira/ui-library";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../../hooks/useApi";
import DataCdrDetails from "../../../components/Cdr/DataCdrDetails";

const DataCdrDetailPage = () => {
    const { cdrId } = useParams();
    const { cdrApi } = useApi();

    const dataCdrQuery = useQuery(["cdr", "data", cdrId], () => cdrApi.getDataCdrRecord({ cdrId: cdrId || "" }), {
        enabled: !!cdrId,
    });
    return (
        <PageLayout
            title={<PageTitle title={"CDR Details"} />}
            navigation={<PageBackButton text={"Back to CDR"} path={`/cdr`} />}
        >
            <DataCdrDetails query={dataCdrQuery} />
        </PageLayout>
    );
};

export default DataCdrDetailPage;
