import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormContainer, FormField } from "@cobira/ui-library";
import { Button, ButtonGroup, Input } from "@chakra-ui/react";
import { Customer } from "../../api";

const UpdateCustomerFormSchema = z.object({
    customerName: z.string().min(1, "Name is required"),
    shippingAddress: z.string().optional(),
    contactName: z.string().optional(),
    contactEmail: z.string().optional(),
    accountNumber: z.string().optional(),
});

export type UpdateCustomerFormSchemaType = z.infer<typeof UpdateCustomerFormSchema>;

export interface UpdateCustomerFormProps {
    customer: Customer;
    onSubmit: (form: UpdateCustomerFormSchemaType) => void;
    onCancel?: () => void;
}

const UpdateCustomerForm = ({ customer, onSubmit, onCancel }: UpdateCustomerFormProps) => {
    const {
        formState: { errors },
        handleSubmit,
        register,
    } = useForm<UpdateCustomerFormSchemaType>({
        resolver: zodResolver(UpdateCustomerFormSchema),
        defaultValues: {
            contactName: customer.contactName,
            contactEmail: customer.contactEmail,
            shippingAddress: customer.shippingAddress,
            accountNumber: customer.accountNumber,
            customerName: customer.customerName,
        },
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Name"} error={errors?.customerName}>
                    <Input {...register("customerName")} />
                </FormField>
                <FormField label={"Shipping Address"} error={errors?.shippingAddress}>
                    <Input {...register("shippingAddress")} />
                </FormField>
                <FormField label={"Contact Name"} error={errors?.contactName}>
                    <Input {...register("contactName")} />
                </FormField>
                <FormField label={"Contact Email"} error={errors?.contactEmail}>
                    <Input {...register("contactEmail")} />
                </FormField>
                <FormField label={"Account Number"} error={errors?.accountNumber}>
                    <Input {...register("accountNumber")} />
                </FormField>
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Update
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default UpdateCustomerForm;
