/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        Action,
        Invoice,
        InvoiceLinePage,
        InvoicePage,
        Month,
    } from '../models';
        import {
            ActionFromJSON,
            ActionToJSON,
            InvoiceFromJSON,
            InvoiceToJSON,
            InvoiceLinePageFromJSON,
            InvoiceLinePageToJSON,
            InvoicePageFromJSON,
            InvoicePageToJSON,
            MonthFromJSON,
            MonthToJSON,
        } from '../models';

            export interface InvoiceApiCreateInvoiceRequest {
                year: number;
                month: Month;
                customerIds?: Array<string>;
            }

            export interface InvoiceApiGetInvoiceRequest {
                invoiceId: string;
            }

            export interface InvoiceApiGetInvoiceLinesRequest {
                invoiceId: string;
                pageNumber: number;
                pageSize: number;
            }

            export interface InvoiceApiGetInvoicesRequest {
                pageNumber: number;
                pageSize: number;
                year?: number;
                month?: Month;
                customerId?: string;
            }

        /**
        * 
        */
            export class InvoiceApi extends runtime.BaseAPI {

            /**
                * Computes a new invoice for the specified month in the chosen year.
                * Create new invoices
            */
            async createInvoiceRaw(requestParameters: InvoiceApiCreateInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Action>> {
                    if (requestParameters.year === null || requestParameters.year === undefined) {
                    throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling createInvoice.');
                    }

                    if (requestParameters.month === null || requestParameters.month === undefined) {
                    throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling createInvoice.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.year !== undefined) {
                            queryParameters['year'] = requestParameters.year;
                    }

                    if (requestParameters.month !== undefined) {
                            queryParameters['month'] = requestParameters.month;
                    }

                    if (requestParameters.customerIds) {
                        queryParameters['customerIds'] = requestParameters.customerIds;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/invoices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => ActionFromJSON(jsonValue));
            }

            /**
                * Computes a new invoice for the specified month in the chosen year.
                * Create new invoices
            */
                async createInvoice(requestParameters: InvoiceApiCreateInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Action> {
                    const response = await this.createInvoiceRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get a single invoice by its id.
                * Get invoice
            */
            async getInvoiceRaw(requestParameters: InvoiceApiGetInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Invoice>> {
                    if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
                    throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling getInvoice.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/invoices/{invoiceId}`.replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceFromJSON(jsonValue));
            }

            /**
                * Get a single invoice by its id.
                * Get invoice
            */
                async getInvoice(requestParameters: InvoiceApiGetInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Invoice> {
                    const response = await this.getInvoiceRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetch the invoice lines associated with the requested invoice. The lines include both recurring fees configured for the customer, as well as the summarized result of the network usage for the invoice period. Lines will be available once the invoice enters the \'PREVIEWING\' state.
                * Get invoice lines
            */
            async getInvoiceLinesRaw(requestParameters: InvoiceApiGetInvoiceLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoiceLinePage>> {
                    if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
                    throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling getInvoiceLines.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getInvoiceLines.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getInvoiceLines.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/invoices/{invoiceId}/lines`.replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceLinePageFromJSON(jsonValue));
            }

            /**
                * Fetch the invoice lines associated with the requested invoice. The lines include both recurring fees configured for the customer, as well as the summarized result of the network usage for the invoice period. Lines will be available once the invoice enters the \'PREVIEWING\' state.
                * Get invoice lines
            */
                async getInvoiceLines(requestParameters: InvoiceApiGetInvoiceLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoiceLinePage> {
                    const response = await this.getInvoiceLinesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get existing invoices.
                * Get invoices
            */
            async getInvoicesRaw(requestParameters: InvoiceApiGetInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoicePage>> {
                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getInvoices.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getInvoices.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.year !== undefined) {
                            queryParameters['year'] = requestParameters.year;
                    }

                    if (requestParameters.month !== undefined) {
                            queryParameters['month'] = requestParameters.month;
                    }

                    if (requestParameters.customerId !== undefined) {
                            queryParameters['customerId'] = requestParameters.customerId;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/invoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => InvoicePageFromJSON(jsonValue));
            }

            /**
                * Get existing invoices.
                * Get invoices
            */
                async getInvoices(requestParameters: InvoiceApiGetInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoicePage> {
                    const response = await this.getInvoicesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
