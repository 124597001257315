import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormContainer, FormField } from "@cobira/ui-library";
import { Button, ButtonGroup, Input } from "@chakra-ui/react";

const CreateCustomerFormSchema = z.object({
    customerName: z.string().min(1, "Name is required"),
    shippingAddress: z.string().optional(),
    contactName: z.string().optional(),
    contactEmail: z.string().optional(),
    accountNumber: z.string().optional(),
});

export type CreateCustomerFormSchemaType = z.infer<typeof CreateCustomerFormSchema>;

export interface CreateBillingGroupFormProps {
    onSubmit: (form: CreateCustomerFormSchemaType) => void;
    onCancel?: () => void;
}

const CreateCustomerForm = ({ onSubmit, onCancel }: CreateBillingGroupFormProps) => {
    const {
        formState: { errors },
        handleSubmit,
        register,
    } = useForm<CreateCustomerFormSchemaType>({
        resolver: zodResolver(CreateCustomerFormSchema),
        defaultValues: {
            contactName: "N/A",
            contactEmail: "N/A",
            shippingAddress: "N/A",
        },
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Name"} error={errors?.customerName}>
                    <Input {...register("customerName")} />
                </FormField>
                <FormField label={"Shipping Address"} error={errors?.shippingAddress}>
                    <Input {...register("shippingAddress")} />
                </FormField>
                <FormField label={"Contact Name"} error={errors?.contactName}>
                    <Input {...register("contactName")} />
                </FormField>
                <FormField label={"Contact Email"} error={errors?.contactEmail}>
                    <Input {...register("contactEmail")} />
                </FormField>
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Create
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default CreateCustomerForm;
