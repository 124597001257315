import React from "react";
import { CobiraTabs, PageLayout, PageTitle } from "@cobira/ui-library";
import DataCdrPanel from "../cdr/CdrListPage/DataCdrPanel/DataCdrPanel";
import SmsCdrPanel from "../cdr/CdrListPage/SmsCdrPanel/SmsCdrPanel";
import { tab } from "../../utils/tabUtils";

const BillingPage = () => {
    const billingTabs = [tab("Data Usage", <DataCdrPanel />), tab("SMS Usage", <SmsCdrPanel />)];

    return (
        <PageLayout title={<PageTitle title={"Billing"} />}>
            <CobiraTabs tabs={billingTabs} />
        </PageLayout>
    );
};

export default BillingPage;
