import React from "react";
import { Grid, VStack, Wrap } from "@chakra-ui/react";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import {
    Alpha2Code,
    CountryInfo,
    DataPackageAllowance,
    Duration,
    getCountry,
    Paper,
    PlaceholderText,
    SmsPackageAllowance,
    TextColumn,
} from "@cobira/ui-library";
import { UsagePackageAutomaticActivationTypeLabels } from "../../labels/UsagePackageAutomaticActivationTypeLabels";
import DeletedAt from "../DeletedAt/DeletedAt";
import { UsagePackageType } from "../../api";

export interface UsagePackageTypeDetailsProps {
    query: UseQueryResult<UsagePackageType>;
}

const UsagePackageTypeDetails = ({ query: { data: usagePackageType, isLoading } }: UsagePackageTypeDetailsProps) => {
    return (
        <VStack w={"100%"} align={"stretch"} gap={6}>
            <Paper header={"Details"} withDivider>
                <Grid templateColumns="auto auto" gap="4">
                    <TextColumn heading="Name" grid isLoading={isLoading}>
                        {usagePackageType?.customName}
                    </TextColumn>
                    <TextColumn heading="Description" grid isLoading={isLoading}>
                        <PlaceholderText text={usagePackageType?.description} />
                    </TextColumn>
                    <TextColumn heading="Duration" grid isLoading={isLoading}>
                        <Duration value={usagePackageType?.packageDuration} />
                    </TextColumn>
                    <TextColumn heading="Activation Behaviour" grid isLoading={isLoading}>
                        <PlaceholderText
                            text={
                                UsagePackageAutomaticActivationTypeLabels[
                                    usagePackageType?.automaticActivationType || "UNKNOWN"
                                ]
                            }
                        />
                    </TextColumn>
                    <TextColumn heading="Data Allowance" grid isLoading={isLoading}>
                        <DataPackageAllowance
                            allowance={usagePackageType?.dataUsageAllowance?.dataAllowance || undefined}
                            enabled={usagePackageType?.dataUsageAllowance?.enabled}
                        />
                    </TextColumn>
                    <TextColumn heading="SMS Allowance" grid isLoading={isLoading}>
                        <SmsPackageAllowance
                            allowance={usagePackageType?.smsUsageAllowance?.smsAllowance || undefined}
                            enabled={usagePackageType?.smsUsageAllowance?.enabled}
                        />
                    </TextColumn>
                    <TextColumn heading="Deleted At" grid isLoading={isLoading}>
                        <DeletedAt deletedAt={usagePackageType?.deletedAt} />
                    </TextColumn>
                </Grid>
            </Paper>
            <Paper header={"Countries"} withDivider>
                <Wrap w={"100%"}>
                    {usagePackageType?.supportedCountries
                        ?.map(alpha2 => getCountry(alpha2 as Alpha2Code))
                        .map(country => <CountryInfo key={country.iso2} country={country} />)}
                </Wrap>
            </Paper>
        </VStack>
    );
};

export default UsagePackageTypeDetails;
