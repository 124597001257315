import React from "react";
import { TabPanel } from "@chakra-ui/react";
import { SimCard, UsagePackage } from "../../../../api";
import { useApi } from "../../../../hooks/useApi";
import { DataText, CobiraTable, DateTime, PlaceholderText, UsagePackageStatusIndicator } from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { UsagePackageTypeCell } from "../../../../components/Cells/UsagePackageTypeCell/UsagePackageTypeCell";
import { createColumnHelper } from "@tanstack/react-table";

export interface SimCardUsagePackagesPanelProps {
    query: UseQueryResult<SimCard>;
}

const usagePackageColumn = createColumnHelper<UsagePackage>();
const COLUMNS = [
    usagePackageColumn.accessor("packageState", {
        id: "packageState",
        header: "Package State",
        cell: row => <UsagePackageStatusIndicator state={row.getValue()} withLabel />,
    }),
    usagePackageColumn.accessor("usagePackageTypeId", {
        id: "packageName",
        header: "Package Name",
        cell: row => (
            <UsagePackageTypeCell
                usagePackageTypeId={row.getValue()}
                property={usagePackageType => <PlaceholderText text={usagePackageType?.customName} />}
            />
        ),
    }),
    usagePackageColumn.accessor("dataRemaining.dataInBytes", {
        id: "dataRemaining",
        header: "Data Remaining",
        cell: row => <DataText bytes={row.getValue()} decimals={2} />,
    }),
    usagePackageColumn.accessor("smsRemaining", {
        id: "smsRemaining",
        header: "SMS Remaining",
        cell: row => <PlaceholderText text={`${row.getValue()}`} />,
    }),
    usagePackageColumn.accessor("activatedAt", {
        id: "activatedAt",
        header: "Activated At",
        cell: row => <DateTime value={row.getValue()} />,
    }),
    usagePackageColumn.accessor("terminatedAt", {
        id: "terminatedAt",
        header: "Terminated At",
        cell: row => <DateTime value={row.getValue()} />,
    }),
    usagePackageColumn.accessor("expiresAt", {
        id: "expiresAt",
        header: "Expires At",
        cell: row => <DateTime value={row.getValue()} />,
    }),
];

const SimCardUsagePackagesPanel = ({ query: { data: value } }: SimCardUsagePackagesPanelProps) => {
    const { simCardApi } = useApi();

    const { data: usagePackages, isLoading: isLoadingDataSessions } = useQuery(
        ["simcards", value?.icc, "usagepackages"],
        () => simCardApi.getSimCardUsagePackages({ icc: value?.icc || "" }),
        { enabled: value?.icc !== undefined },
    );

    return (
        <TabPanel w={"100%"}>
            <CobiraTable columns={COLUMNS} data={usagePackages || []} isLoading={isLoadingDataSessions} />
        </TabPanel>
    );
};

export default SimCardUsagePackagesPanel;
