import React from "react";
import { InfoPopover, pluralize, SimpleInfo } from "@cobira/ui-library";
import { NetworkCredentialType } from "../../../api";
import { SkeletonText, Text } from "@chakra-ui/react";
import { useQueries } from "@tanstack/react-query";
import { useApi } from "../../../hooks/useApi";

export interface NetworkCredentialTypesCellProps {
    networkCredentialTypeIds?: Set<string> | string[];
}

const NetworkCredentialTypesCell = ({ networkCredentialTypeIds }: NetworkCredentialTypesCellProps) => {
    const { productApi } = useApi();

    const networkCredentialTypeQueries = useQueries({
        queries: Array.from(networkCredentialTypeIds || []).map(networkCredentialTypeId => ({
            queryKey: ["networkcredentialtypes", networkCredentialTypeId],
            queryFn: () => productApi.getNetworkCredentialType({ networkCredentialTypeId: networkCredentialTypeId }),
            enabled: !!networkCredentialTypeId,
        })),
    });

    const isLoading = networkCredentialTypeQueries
        .map(result => result.isFetching)
        .reduce((previousValue, currentValue) => previousValue || currentValue, false);
    const networkCredentialTypes = networkCredentialTypeQueries.map(result => result?.data);

    const mapNetworkCredentialType = (networkCredentialType: NetworkCredentialType): SimpleInfo => ({
        title: `(${networkCredentialType.plmn}) ${networkCredentialType.imsiProvider}`,
        description: networkCredentialType.description,
    });

    const mapNetworkCredentialTypesText = (networkCredentialTypes: Array<NetworkCredentialType | undefined>) => {
        if (networkCredentialTypes.length === 0) {
            return "No network credential types";
        } else {
            return `${networkCredentialTypes.length} ${pluralize("network credential type", networkCredentialTypes.length)}`;
        }
    };

    if (isLoading) {
        return <SkeletonText />;
    }

    return (
        <InfoPopover
            value={networkCredentialTypes
                .filter(Boolean)
                .map(value => mapNetworkCredentialType(value as NetworkCredentialType))}
        >
            <Text>{mapNetworkCredentialTypesText(networkCredentialTypes)}</Text>
        </InfoPopover>
    );
};

export default NetworkCredentialTypesCell;
