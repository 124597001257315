import React from "react";
import DataCdrPanel from "./DataCdrPanel/DataCdrPanel";
import SmsCdrPanel from "./SmsCdrPanel/SmsCdrPanel";
import { CobiraTabs, PageLayout, PageTitle } from "@cobira/ui-library";
import { tab } from "../../../utils/tabUtils";

const CdrListPage = () => {
    const cdrListTabs = [tab("Data Usage", <DataCdrPanel />), tab("SMS Usage", <SmsCdrPanel />)];

    return (
        <PageLayout title={<PageTitle title={"Usage Records"} />}>
            <CobiraTabs tabs={cdrListTabs} />
        </PageLayout>
    );
};

export default CdrListPage;
