import { createColumnHelper } from "@tanstack/react-table";
import { BillingGroup, CdrState, Customer, SmsCdr } from "../../../../api";
import {
    CobiraTable,
    Country,
    CountryInfo,
    DateTime,
    getCountry,
    SearchBar,
    SearchSingleInput,
    singleInput,
    usePageNavigation,
    usePagination,
    useSearch,
} from "@cobira/ui-library";
import { BillingGroupCell } from "../../../../components/Cells/BillingGroupCell/BillingGroupCell";
import React from "react";
import { TabPanel, VStack } from "@chakra-ui/react";
import { SearchSelectCustomerInput } from "../../../../components/SimCardSearch/SearchSelectCustomerInput/SearchSelectCustomerInput";
import { SearchSelectBillingGroupInput } from "../../../../components/SimCardSearch/SearchSelectBillingGroupInput/SearchSelectBillingGroupInput";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../../../hooks/useApi";
import {
    CdrStateSelect,
    SearchSelectCdrStateInput,
} from "../../../../components/SearchSelectCdrState/SearchSelectCdrStateInput";
import { CustomerCell } from "../../../../components/Cells/CustomerCell/CustomerCell";
import { NetworkCredentialTypeCell } from "../../../../components/Cells/NetworkCredentialTypeCell/NetworkCredentialTypeCell";
import { CostPlanCell } from "../../../../components/Cells/CostPlanCell/CostPlanCell";
import { RatePlanCell } from "../../../../components/Cells/RatePlanCell/RatePlanCell";
import { SmsDirectionLabels } from "../../../../labels/SmsDirectionLabels";
import { CdrStateLabels } from "../../../../labels/CdrStateLabels";

const cdrColumn = createColumnHelper<SmsCdr>();
const COLUMNS = [
    cdrColumn.accessor("status", {
        id: "status",
        header: "State",
        cell: row => CdrStateLabels[row.getValue() || CdrState.Unknown],
    }),
    cdrColumn.accessor("icc", {
        id: "icc",
        header: "ICC",
        cell: row => row.getValue(),
    }),
    cdrColumn.accessor("countryCode", {
        id: "country",
        header: "Country",
        cell: row => <CountryInfo country={getCountry(row.getValue())} />,
    }),
    cdrColumn.accessor("billingGroupId", {
        id: "billingGroup",
        header: "Billing Group",
        cell: row => <BillingGroupCell billingGroupId={row.getValue()} />,
    }),
    cdrColumn.accessor("customerId", {
        id: "customer",
        header: "Customer",
        cell: row => <CustomerCell customerId={row.getValue()} />,
    }),
    cdrColumn.accessor("direction", {
        id: "direction",
        header: "Direction",
        cell: row => SmsDirectionLabels[row.getValue()],
    }),
    cdrColumn.accessor("recordedAt", {
        id: "recordedAt",
        header: "Sent At",
        cell: row => <DateTime value={row.getValue()} />,
    }),
    cdrColumn.accessor("imsi", {
        id: "imsi",
        header: "IMSI",
        cell: row => row.getValue(),
    }),
    cdrColumn.accessor("cost", {
        id: "cost",
        header: "Cost",
        cell: row => row.getValue(),
    }),
    cdrColumn.accessor("networkCredentialTypeId", {
        id: "networkCredentialType",
        header: "Network Credential Type",
        cell: row => <NetworkCredentialTypeCell networkCredentialTypeId={row.getValue()} />,
    }),
    cdrColumn.accessor(row => row, {
        id: "costPlan",
        header: "Cost Plan",
        cell: row => (
            <CostPlanCell
                networkCredentialTypeId={row.row.original.networkCredentialTypeId}
                costPlanId={row.row.original.costPlanId}
            />
        ),
    }),
    cdrColumn.accessor("ratePlanId", {
        id: "ratePlan",
        header: "Rate Plan",
        cell: row => <RatePlanCell ratePlanId={row.getValue()} />,
    }),
];

export interface SmsCdrPanelProps {}

const SmsCdrPanel = () => {
    const { cdrApi } = useApi();
    const pageNavigation = usePageNavigation<SmsCdr>({ route: value => `/cdr/sms/${value.id}` });
    const { pageState, setPageState, resetPageState } = usePagination({
        pageIndex: 0,
        pageSize: 20,
    });
    const search = useSearch({
        config: {
            icc: singleInput<string>("ICC"),
            plmn: singleInput<string>("PLMN"),
            billingGroup: singleInput<BillingGroup>("Billing Group", { display: value => value.name }),
            customer: singleInput<Customer>("Customer", { display: value => value.customerName }),
            country: singleInput<Country>("Country", { display: value => value.name }),
            state: singleInput<CdrStateSelect>("State", { display: value => value.label }),
        },
        onChange: resetPageState,
    });
    const { state } = search;

    const { data: smsCdrs, isLoading } = useQuery(["cdrs", "sms", pageState, state], () => {
        const now = new Date();
        const oneMonthAgo = new Date(now);
        oneMonthAgo.setMonth(now.getMonth() - 1);
        return cdrApi.getSmsCdr({
            pageNumber: pageState.pageIndex,
            pageSize: pageState.pageSize,
            fromDate: oneMonthAgo,
            toDate: now,
            icc: state?.icc?.value || undefined,
            billingGroupId: state?.billingGroup?.value?.id,
            plmn: state?.plmn?.value || undefined,
            customerId: state?.customer?.value?.id,
            countryCode: state?.country?.value?.iso2,
            status: state?.state?.value?.value || undefined,
        });
    });

    return (
        <TabPanel>
            <VStack w={"100%"} gap={2}>
                <SearchBar
                    defaultFilterInputId={"icc"}
                    useSearch={search}
                    filterInputs={[
                        {
                            id: "icc",
                            menuLabel: "ICC",
                            inputComponent: (
                                <SearchSingleInput
                                    registration={search.registerInput({ id: "icc" })}
                                    placeholder={"Search by ICC"}
                                />
                            ),
                        },
                        {
                            id: "plmn",
                            menuLabel: "PLMN",
                            inputComponent: (
                                <SearchSingleInput
                                    registration={search.registerInput({ id: "plmn" })}
                                    placeholder={"Search by PLMN"}
                                />
                            ),
                        },
                        {
                            id: "customerId",
                            menuLabel: "Customer",
                            inputComponent: (
                                <SearchSelectCustomerInput registration={search.registerInput({ id: "customer" })} />
                            ),
                        },
                        {
                            id: "billingGroupId",
                            menuLabel: "Billing Group",
                            inputComponent: (
                                <SearchSelectBillingGroupInput
                                    registration={search.registerInput({ id: "billingGroup" })}
                                />
                            ),
                        },
                        {
                            id: "cdrState",
                            menuLabel: "State",
                            inputComponent: (
                                <SearchSelectCdrStateInput registration={search.registerInput({ id: "state" })} />
                            ),
                        },
                    ]}
                />
                <CobiraTable
                    sizing={"fit-page"}
                    columns={COLUMNS}
                    data={smsCdrs?.content || []}
                    isLoading={isLoading}
                    withPagination={{
                        pageIndex: pageState.pageIndex,
                        pageSize: pageState.pageSize,
                        onPaginationChange: setPageState,
                        totalRowCount: smsCdrs?.pageProperties?.totalElements || 0,
                        totalPageCount: smsCdrs?.pageProperties?.totalPages || 0,
                    }}
                    withColumnSelection={{
                        tableKey: "sms-cdr-table",
                        maxColumnCount: 8,
                    }}
                    withRowClick={{
                        enableHoverStyle: true,
                        onRowClicked: pageNavigation.navigate,
                    }}
                />
            </VStack>
        </TabPanel>
    );
};

export default SmsCdrPanel;
