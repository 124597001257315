import { Currency, PriceBound, RecurringFeeContext } from "../api";
import { RecurringFeeContextLabels } from "../labels/RecurringFeeContextLabels";
import { convertData } from "@cobira/ui-library";

const byLowerBoundAscending = (left: { lowerBound: number }, right: { lowerBound: number }) => {
    return left.lowerBound - right.lowerBound;
};

export const sortPriceBounds = <T extends { lowerBound: number }>(bounds: T[]) => bounds.sort(byLowerBoundAscending);

export const priceBoundLabel = (bound: PriceBound, context: RecurringFeeContext) => {
    if (context === "NONE") {
        return RecurringFeeContextLabels[context];
    }

    if (bound.upperBound === undefined) {
        if (context === "BYTES_USED") {
            return `From ${convertData(bound.lowerBound).toString()} and more`;
        } else {
            return `From ${bound.lowerBound} ${RecurringFeeContextLabels[context]} and more`;
        }
    } else {
        if (context === "BYTES_USED") {
            return `From ${convertData(bound.lowerBound).toString()} to ${convertData(bound.upperBound).toString()}`;
        } else {
            return `From ${bound.lowerBound} to ${bound.upperBound} ${RecurringFeeContextLabels[context]}`;
        }
    }
};

export const CURRENCY_OPTIONS = [Currency.Eur, Currency.Usd, Currency.Dkk];
