/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        CostPlan,
        CostPlanPage,
        CreateProduct,
        NetworkAccessCostPage,
        NetworkCredentialType,
        NetworkCredentialTypePage,
        Product,
        SimCardCapabilityName,
        SimConfiguration,
        SimConfigurationPage,
        TelcoCostPage,
        UpdateProduct,
        YearMonth,
    } from '../models';
        import {
            CostPlanFromJSON,
            CostPlanToJSON,
            CostPlanPageFromJSON,
            CostPlanPageToJSON,
            CreateProductFromJSON,
            CreateProductToJSON,
            NetworkAccessCostPageFromJSON,
            NetworkAccessCostPageToJSON,
            NetworkCredentialTypeFromJSON,
            NetworkCredentialTypeToJSON,
            NetworkCredentialTypePageFromJSON,
            NetworkCredentialTypePageToJSON,
            ProductFromJSON,
            ProductToJSON,
            SimCardCapabilityNameFromJSON,
            SimCardCapabilityNameToJSON,
            SimConfigurationFromJSON,
            SimConfigurationToJSON,
            SimConfigurationPageFromJSON,
            SimConfigurationPageToJSON,
            TelcoCostPageFromJSON,
            TelcoCostPageToJSON,
            UpdateProductFromJSON,
            UpdateProductToJSON,
            YearMonthFromJSON,
            YearMonthToJSON,
        } from '../models';

            export interface ProductApiCreateProductRequest {
                createProduct: CreateProduct;
            }

            export interface ProductApiGetCostPlanNetworkAccessCostsRequest {
                networkCredentialTypeId: string;
                costPlanId: string;
                pageSize: number;
                pageNumber: number;
            }

            export interface ProductApiGetCostPlanTelcoCostsRequest {
                networkCredentialTypeId: string;
                costPlanId: string;
                pageSize: number;
                pageNumber: number;
            }

            export interface ProductApiGetNetworkCredentialTypeRequest {
                networkCredentialTypeId: string;
            }

            export interface ProductApiGetNetworkCredentialTypeCostPlanRequest {
                networkCredentialTypeId: string;
                costPlanId: string;
            }

            export interface ProductApiGetNetworkCredentialTypeCostPlansRequest {
                networkCredentialTypeId: string;
                pageSize: number;
                pageNumber: number;
                yearMonth?: YearMonth;
            }

            export interface ProductApiGetNetworkCredentialTypesRequest {
                pageNumber: number;
                pageSize: number;
            }

            export interface ProductApiGetProductRequest {
                productId: string;
            }

            export interface ProductApiGetProductSimConfigurationRequest {
                productId: string;
            }

            export interface ProductApiGetSimConfigurationRequest {
                simConfigurationId: string;
            }

            export interface ProductApiGetSimConfigurationsRequest {
                pageNumber: number;
                pageSize: number;
                includeDeleted?: boolean | null;
                search?: string;
                simCapability?: SimCardCapabilityName;
                networkCredentialTypeSearch?: string;
            }

            export interface ProductApiUpdateProductRequest {
                productId: string;
                updateProduct: UpdateProduct;
            }

        /**
        * 
        */
            export class ProductApi extends runtime.BaseAPI {

            /**
                * Create a new product from a Cobira SIM configuration
                * Create product
            */
            async createProductRaw(requestParameters: ProductApiCreateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
                    if (requestParameters.createProduct === null || requestParameters.createProduct === undefined) {
                    throw new runtime.RequiredError('createProduct','Required parameter requestParameters.createProduct was null or undefined when calling createProduct.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/products`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: CreateProductToJSON(requestParameters.createProduct),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
            }

            /**
                * Create a new product from a Cobira SIM configuration
                * Create product
            */
                async createProduct(requestParameters: ProductApiCreateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
                    const response = await this.createProductRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetches Network Access costs for the cost plan.
                * Get Network Access Costs
            */
            async getCostPlanNetworkAccessCostsRaw(requestParameters: ProductApiGetCostPlanNetworkAccessCostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NetworkAccessCostPage>> {
                    if (requestParameters.networkCredentialTypeId === null || requestParameters.networkCredentialTypeId === undefined) {
                    throw new runtime.RequiredError('networkCredentialTypeId','Required parameter requestParameters.networkCredentialTypeId was null or undefined when calling getCostPlanNetworkAccessCosts.');
                    }

                    if (requestParameters.costPlanId === null || requestParameters.costPlanId === undefined) {
                    throw new runtime.RequiredError('costPlanId','Required parameter requestParameters.costPlanId was null or undefined when calling getCostPlanNetworkAccessCosts.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getCostPlanNetworkAccessCosts.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getCostPlanNetworkAccessCosts.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/networkcredentialtypes/{networkCredentialTypeId}/costplans/{costPlanId}/networkaccesscosts`.replace(`{${"networkCredentialTypeId"}}`, encodeURIComponent(String(requestParameters.networkCredentialTypeId))).replace(`{${"costPlanId"}}`, encodeURIComponent(String(requestParameters.costPlanId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => NetworkAccessCostPageFromJSON(jsonValue));
            }

            /**
                * Fetches Network Access costs for the cost plan.
                * Get Network Access Costs
            */
                async getCostPlanNetworkAccessCosts(requestParameters: ProductApiGetCostPlanNetworkAccessCostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NetworkAccessCostPage> {
                    const response = await this.getCostPlanNetworkAccessCostsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetches telco costs for the cost plan.
                * Get Telco Costs
            */
            async getCostPlanTelcoCostsRaw(requestParameters: ProductApiGetCostPlanTelcoCostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TelcoCostPage>> {
                    if (requestParameters.networkCredentialTypeId === null || requestParameters.networkCredentialTypeId === undefined) {
                    throw new runtime.RequiredError('networkCredentialTypeId','Required parameter requestParameters.networkCredentialTypeId was null or undefined when calling getCostPlanTelcoCosts.');
                    }

                    if (requestParameters.costPlanId === null || requestParameters.costPlanId === undefined) {
                    throw new runtime.RequiredError('costPlanId','Required parameter requestParameters.costPlanId was null or undefined when calling getCostPlanTelcoCosts.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getCostPlanTelcoCosts.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getCostPlanTelcoCosts.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/networkcredentialtypes/{networkCredentialTypeId}/costplans/{costPlanId}/telcocosts`.replace(`{${"networkCredentialTypeId"}}`, encodeURIComponent(String(requestParameters.networkCredentialTypeId))).replace(`{${"costPlanId"}}`, encodeURIComponent(String(requestParameters.costPlanId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => TelcoCostPageFromJSON(jsonValue));
            }

            /**
                * Fetches telco costs for the cost plan.
                * Get Telco Costs
            */
                async getCostPlanTelcoCosts(requestParameters: ProductApiGetCostPlanTelcoCostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TelcoCostPage> {
                    const response = await this.getCostPlanTelcoCostsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get Network Credential type
            */
            async getNetworkCredentialTypeRaw(requestParameters: ProductApiGetNetworkCredentialTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NetworkCredentialType>> {
                    if (requestParameters.networkCredentialTypeId === null || requestParameters.networkCredentialTypeId === undefined) {
                    throw new runtime.RequiredError('networkCredentialTypeId','Required parameter requestParameters.networkCredentialTypeId was null or undefined when calling getNetworkCredentialType.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/networkcredentialtypes/{networkCredentialTypeId}`.replace(`{${"networkCredentialTypeId"}}`, encodeURIComponent(String(requestParameters.networkCredentialTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => NetworkCredentialTypeFromJSON(jsonValue));
            }

            /**
                * Get Network Credential type
            */
                async getNetworkCredentialType(requestParameters: ProductApiGetNetworkCredentialTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NetworkCredentialType> {
                    const response = await this.getNetworkCredentialTypeRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetches the selected cost plan
                * Get Network Credential Type Cost Plan
            */
            async getNetworkCredentialTypeCostPlanRaw(requestParameters: ProductApiGetNetworkCredentialTypeCostPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CostPlan>> {
                    if (requestParameters.networkCredentialTypeId === null || requestParameters.networkCredentialTypeId === undefined) {
                    throw new runtime.RequiredError('networkCredentialTypeId','Required parameter requestParameters.networkCredentialTypeId was null or undefined when calling getNetworkCredentialTypeCostPlan.');
                    }

                    if (requestParameters.costPlanId === null || requestParameters.costPlanId === undefined) {
                    throw new runtime.RequiredError('costPlanId','Required parameter requestParameters.costPlanId was null or undefined when calling getNetworkCredentialTypeCostPlan.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/networkcredentialtypes/{networkCredentialTypeId}/costplans/{costPlanId}`.replace(`{${"networkCredentialTypeId"}}`, encodeURIComponent(String(requestParameters.networkCredentialTypeId))).replace(`{${"costPlanId"}}`, encodeURIComponent(String(requestParameters.costPlanId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => CostPlanFromJSON(jsonValue));
            }

            /**
                * Fetches the selected cost plan
                * Get Network Credential Type Cost Plan
            */
                async getNetworkCredentialTypeCostPlan(requestParameters: ProductApiGetNetworkCredentialTypeCostPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CostPlan> {
                    const response = await this.getNetworkCredentialTypeCostPlanRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetches cost plans for a given network credential type. Cost plans are chosen based on their `validAt` property.
                * Get Network Credential Type Cost Plans
            */
            async getNetworkCredentialTypeCostPlansRaw(requestParameters: ProductApiGetNetworkCredentialTypeCostPlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CostPlanPage>> {
                    if (requestParameters.networkCredentialTypeId === null || requestParameters.networkCredentialTypeId === undefined) {
                    throw new runtime.RequiredError('networkCredentialTypeId','Required parameter requestParameters.networkCredentialTypeId was null or undefined when calling getNetworkCredentialTypeCostPlans.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getNetworkCredentialTypeCostPlans.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getNetworkCredentialTypeCostPlans.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.yearMonth !== undefined) {
                            queryParameters['yearMonth'] = requestParameters.yearMonth;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/networkcredentialtypes/{networkCredentialTypeId}/costplans`.replace(`{${"networkCredentialTypeId"}}`, encodeURIComponent(String(requestParameters.networkCredentialTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => CostPlanPageFromJSON(jsonValue));
            }

            /**
                * Fetches cost plans for a given network credential type. Cost plans are chosen based on their `validAt` property.
                * Get Network Credential Type Cost Plans
            */
                async getNetworkCredentialTypeCostPlans(requestParameters: ProductApiGetNetworkCredentialTypeCostPlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CostPlanPage> {
                    const response = await this.getNetworkCredentialTypeCostPlansRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get Network Credential types
            */
            async getNetworkCredentialTypesRaw(requestParameters: ProductApiGetNetworkCredentialTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NetworkCredentialTypePage>> {
                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getNetworkCredentialTypes.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getNetworkCredentialTypes.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/networkcredentialtypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => NetworkCredentialTypePageFromJSON(jsonValue));
            }

            /**
                * Get Network Credential types
            */
                async getNetworkCredentialTypes(requestParameters: ProductApiGetNetworkCredentialTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NetworkCredentialTypePage> {
                    const response = await this.getNetworkCredentialTypesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get a specific product.
                * Get product
            */
            async getProductRaw(requestParameters: ProductApiGetProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
                    if (requestParameters.productId === null || requestParameters.productId === undefined) {
                    throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling getProduct.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/products/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
            }

            /**
                * Get a specific product.
                * Get product
            */
                async getProduct(requestParameters: ProductApiGetProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
                    const response = await this.getProductRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Retrieve the SIM configuration made available through the selected product. The SIM configuration is made available by the network implementation.
                * Get SIM configuration
            */
            async getProductSimConfigurationRaw(requestParameters: ProductApiGetProductSimConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimConfiguration>> {
                    if (requestParameters.productId === null || requestParameters.productId === undefined) {
                    throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling getProductSimConfiguration.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/products/{productId}/simconfiguration`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SimConfigurationFromJSON(jsonValue));
            }

            /**
                * Retrieve the SIM configuration made available through the selected product. The SIM configuration is made available by the network implementation.
                * Get SIM configuration
            */
                async getProductSimConfiguration(requestParameters: ProductApiGetProductSimConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimConfiguration> {
                    const response = await this.getProductSimConfigurationRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all available products.
                * Get available products
            */
            async getProductsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Product>>> {
            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductFromJSON));
            }

            /**
                * Get all available products.
                * Get available products
            */
                async getProducts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Product>> {
                    const response = await this.getProductsRaw(initOverrides);
                    return await response.value();
                }

            /**
                * Get a single SIM configuration
                * Get SIM configuration
            */
            async getSimConfigurationRaw(requestParameters: ProductApiGetSimConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimConfiguration>> {
                    if (requestParameters.simConfigurationId === null || requestParameters.simConfigurationId === undefined) {
                    throw new runtime.RequiredError('simConfigurationId','Required parameter requestParameters.simConfigurationId was null or undefined when calling getSimConfiguration.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simconfigurations/{simConfigurationId}`.replace(`{${"simConfigurationId"}}`, encodeURIComponent(String(requestParameters.simConfigurationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SimConfigurationFromJSON(jsonValue));
            }

            /**
                * Get a single SIM configuration
                * Get SIM configuration
            */
                async getSimConfiguration(requestParameters: ProductApiGetSimConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimConfiguration> {
                    const response = await this.getSimConfigurationRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all available SIM configurations.
                * Get available SIM configurations
            */
            async getSimConfigurationsRaw(requestParameters: ProductApiGetSimConfigurationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimConfigurationPage>> {
                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getSimConfigurations.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getSimConfigurations.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.includeDeleted !== undefined) {
                            queryParameters['includeDeleted'] = requestParameters.includeDeleted;
                    }

                    if (requestParameters.search !== undefined) {
                            queryParameters['search'] = requestParameters.search;
                    }

                    if (requestParameters.simCapability !== undefined) {
                            queryParameters['simCapability'] = requestParameters.simCapability;
                    }

                    if (requestParameters.networkCredentialTypeSearch !== undefined) {
                            queryParameters['networkCredentialTypeSearch'] = requestParameters.networkCredentialTypeSearch;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simconfigurations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SimConfigurationPageFromJSON(jsonValue));
            }

            /**
                * Get all available SIM configurations.
                * Get available SIM configurations
            */
                async getSimConfigurations(requestParameters: ProductApiGetSimConfigurationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimConfigurationPage> {
                    const response = await this.getSimConfigurationsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Update the information regarding a product.
                * Update product
            */
            async updateProductRaw(requestParameters: ProductApiUpdateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
                    if (requestParameters.productId === null || requestParameters.productId === undefined) {
                    throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling updateProduct.');
                    }

                    if (requestParameters.updateProduct === null || requestParameters.updateProduct === undefined) {
                    throw new runtime.RequiredError('updateProduct','Required parameter requestParameters.updateProduct was null or undefined when calling updateProduct.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/products/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
                                body: UpdateProductToJSON(requestParameters.updateProduct),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
            }

            /**
                * Update the information regarding a product.
                * Update product
            */
                async updateProduct(requestParameters: ProductApiUpdateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
                    const response = await this.updateProductRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
