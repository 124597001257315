/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NetworkCapability } from './NetworkCapability';
import {
    NetworkCapabilityFromJSON,
    NetworkCapabilityFromJSONTyped,
    NetworkCapabilityToJSON,
} from './NetworkCapability';

/**
 * 
 * @export
 * @interface Network
 */
export interface Network {
    /**
     * 
     * @type {Set<string>}
     * @memberof Network
     */
    productIds: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    networkName: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    vpmn: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    plmn: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    apn: string;
    /**
     * 
     * @type {Set<NetworkCapability>}
     * @memberof Network
     */
    networkCapabilities: Set<NetworkCapability>;
}

/**
 * Check if a given object implements the Network interface.
 */
export function instanceOfNetwork(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productIds" in value;
    isInstance = isInstance && "networkName" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "vpmn" in value;
    isInstance = isInstance && "plmn" in value;
    isInstance = isInstance && "apn" in value;
    isInstance = isInstance && "networkCapabilities" in value;

    return isInstance;
}

export function NetworkFromJSON(json: any): Network {
    return NetworkFromJSONTyped(json, false);
}

export function NetworkFromJSONTyped(json: any, ignoreDiscriminator: boolean): Network {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productIds': json['productIds'],
        'networkName': json['networkName'],
        'country': json['country'],
        'vpmn': json['vpmn'],
        'plmn': json['plmn'],
        'apn': json['apn'],
        'networkCapabilities': (new Set((json['networkCapabilities'] as Array<any>).map(NetworkCapabilityFromJSON))),
    };
}

export function NetworkToJSON(value?: Network | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productIds': Array.from(value.productIds as Set<any>),
        'networkName': value.networkName,
        'country': value.country,
        'vpmn': value.vpmn,
        'plmn': value.plmn,
        'apn': value.apn,
        'networkCapabilities': (Array.from(value.networkCapabilities as Set<any>).map(NetworkCapabilityToJSON)),
    };
}

