import { Controller, ControllerProps, FieldValues, Path } from "react-hook-form";
import React from "react";
import { MonthInput } from "@cobira/ui-library";

export type ControlledMonthInputProps<TFieldValues extends FieldValues, TName extends Path<TFieldValues>> = Omit<
    ControllerProps<TFieldValues, TName>,
    "render"
>;

export const ControlledMonthInput = <TFieldValues extends FieldValues, TName extends Path<TFieldValues>>(
    props: ControlledMonthInputProps<TFieldValues, TName>,
) => {
    return (
        <Controller
            {...props}
            render={({ field: { value, onChange } }) => (
                <MonthInput onChange={onChange} value={value} isMulti={false} />
            )}
        />
    );
};
