import React, { useState } from "react";
import { SearchAsyncSelectInput, SearchSingleInputProps, SearchStateConfig } from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { SimConfiguration } from "../../api";
import { useApi } from "../../hooks/useApi";

export interface SearchSelectSimConfigurationInputProps<
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
> extends Omit<SearchSingleInputProps<SimConfiguration, K, TSearchStateConfig>, "label"> {}

export const SearchSelectSimConfigurationInput = <
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
>({
    registration,
    placeholder = "Select SIM Configuration",
}: SearchSelectSimConfigurationInputProps<K, TSearchStateConfig>) => {
    const { productApi } = useApi();
    const [simConfigurationSearchInput, setSimConfigurationSearchInput] = useState<string | null>(null);

    const { data: simConfigurations } = useQuery(["simconfigurations", simConfigurationSearchInput], () =>
        productApi.getSimConfigurations({
            pageNumber: 0,
            pageSize: 20,
            search: simConfigurationSearchInput || undefined,
            includeDeleted: true,
        }),
    );

    return (
        <SearchAsyncSelectInput
            placeholder={placeholder}
            items={simConfigurations?.content || []}
            displayMapping={value => value.name}
            autocompleteAbleMapping={value => value.name}
            registration={registration}
            onInputChange={setSimConfigurationSearchInput}
        />
    );
};
