import React from "react";
import { AccountIcon, ClientIcon, DashboardIcon, SidebarItem, SimCardIcon } from "@cobira/ui-library";
import AccountSidebarLink from "./AccountSidebarLink/AccountSidebarLink";

export const SidebarContent: SidebarItem[] = [
    {
        title: "Dashboards",
        path: "/",
        icon: DashboardIcon,
        items: [
            { title: "Main Dashboard", path: "/dashboards/account" },
            { title: "Customer Trends", path: "/dashboards/customer-compare" },
        ],
    },
    {
        title: "Clients",
        icon: ClientIcon,
        items: [
            { title: "Customers", path: "/customers" },
            { title: "Resellers", path: "/tenants" },
        ],
    },
    {
        title: "SIM Management",
        icon: SimCardIcon,
        items: [
            { title: "Inventory", path: "/simcards" },
            { title: "Activity", path: "/actions" },
        ],
    },
    {
        title: <AccountSidebarLink />,
        icon: AccountIcon,
        items: [
            { title: "Configuration", path: "configuration" },
            { title: "Billing", path: "billing" },
            { title: "Team", path: "/team" },
            { title: "API Integrations", path: "/integrations" },
        ],
    },
];
