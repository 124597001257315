/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A model describing a billing group, which tenant it belongs to and what type it is
 * @export
 * @interface BillingGroup
 */
export interface BillingGroup {
    /**
     * 
     * @type {string}
     * @memberof BillingGroup
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BillingGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BillingGroup
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingGroup
     */
    ratePlanId?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingGroup
     */
    customerId: string;
}

/**
 * Check if a given object implements the BillingGroup interface.
 */
export function instanceOfBillingGroup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "customerId" in value;

    return isInstance;
}

export function BillingGroupFromJSON(json: any): BillingGroup {
    return BillingGroupFromJSONTyped(json, false);
}

export function BillingGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'ratePlanId': !exists(json, 'ratePlanId') ? undefined : json['ratePlanId'],
        'customerId': json['customerId'],
    };
}

export function BillingGroupToJSON(value?: BillingGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'ratePlanId': value.ratePlanId,
        'customerId': value.customerId,
    };
}

