import React from "react";
import { LoadingSkeleton, PlaceholderText, QueryCell } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";

export interface CostPlanCellProps {
    networkCredentialTypeId?: string;
    costPlanId?: string;
}

export const CostPlanCell = ({ networkCredentialTypeId, costPlanId }: CostPlanCellProps) => {
    const { productApi } = useApi();
    if (!networkCredentialTypeId || !costPlanId) {
        return <PlaceholderText text={null} />;
    }

    return (
        <QueryCell
            loadingComponent={<LoadingSkeleton />}
            query={{
                queryFn: () =>
                    productApi.getNetworkCredentialTypeCostPlan({
                        networkCredentialTypeId: networkCredentialTypeId,
                        costPlanId: costPlanId,
                    }),
                queryKey: ["networkcredentialtypes", networkCredentialTypeId, "costplans", costPlanId],
            }}
            render={result => <PlaceholderText text={result?.data?.name} />}
        />
    );
};
