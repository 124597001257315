import React from "react";
import { NetworkCredentialType } from "../../api";
import { Box, Text } from "@chakra-ui/react";

export interface InlineNetworkCredentialTypeProps {
    networkCredentialType?: NetworkCredentialType;
}

const InlineNetworkCredentialType = ({ networkCredentialType }: InlineNetworkCredentialTypeProps) => {
    if (!networkCredentialType) {
        return null;
    }
    return (
        <Box>
            <Text fontSize={"md"} color={"header"} fontWeight={"bold"}>
                {networkCredentialType.plmn}
            </Text>
            <Text>{networkCredentialType.imsiProvider}</Text>
        </Box>
    );
};

export default InlineNetworkCredentialType;
