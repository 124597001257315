/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AutomaticActivationTypes } from './AutomaticActivationTypes';
import {
    AutomaticActivationTypesFromJSON,
    AutomaticActivationTypesFromJSONTyped,
    AutomaticActivationTypesToJSON,
} from './AutomaticActivationTypes';
import type { DataUsageAllowance } from './DataUsageAllowance';
import {
    DataUsageAllowanceFromJSON,
    DataUsageAllowanceFromJSONTyped,
    DataUsageAllowanceToJSON,
} from './DataUsageAllowance';
import type { SmsUsageAllowance } from './SmsUsageAllowance';
import {
    SmsUsageAllowanceFromJSON,
    SmsUsageAllowanceFromJSONTyped,
    SmsUsageAllowanceToJSON,
} from './SmsUsageAllowance';

/**
 * 
 * @export
 * @interface UsagePackageType
 */
export interface UsagePackageType {
    /**
     * 
     * @type {string}
     * @memberof UsagePackageType
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UsagePackageType
     */
    customName: string;
    /**
     * 
     * @type {string}
     * @memberof UsagePackageType
     */
    description: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UsagePackageType
     */
    supportedCountries: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UsagePackageType
     */
    packageDuration: string;
    /**
     * 
     * @type {AutomaticActivationTypes}
     * @memberof UsagePackageType
     */
    automaticActivationType: AutomaticActivationTypes;
    /**
     * 
     * @type {DataUsageAllowance}
     * @memberof UsagePackageType
     */
    dataUsageAllowance: DataUsageAllowance;
    /**
     * 
     * @type {SmsUsageAllowance}
     * @memberof UsagePackageType
     */
    smsUsageAllowance: SmsUsageAllowance;
    /**
     * 
     * @type {Date}
     * @memberof UsagePackageType
     */
    deletedAt?: Date;
}

/**
 * Check if a given object implements the UsagePackageType interface.
 */
export function instanceOfUsagePackageType(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "customName" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "supportedCountries" in value;
    isInstance = isInstance && "packageDuration" in value;
    isInstance = isInstance && "automaticActivationType" in value;
    isInstance = isInstance && "dataUsageAllowance" in value;
    isInstance = isInstance && "smsUsageAllowance" in value;

    return isInstance;
}

export function UsagePackageTypeFromJSON(json: any): UsagePackageType {
    return UsagePackageTypeFromJSONTyped(json, false);
}

export function UsagePackageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsagePackageType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customName': json['customName'],
        'description': json['description'],
        'supportedCountries': json['supportedCountries'],
        'packageDuration': json['packageDuration'],
        'automaticActivationType': AutomaticActivationTypesFromJSON(json['automaticActivationType']),
        'dataUsageAllowance': DataUsageAllowanceFromJSON(json['dataUsageAllowance']),
        'smsUsageAllowance': SmsUsageAllowanceFromJSON(json['smsUsageAllowance']),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
    };
}

export function UsagePackageTypeToJSON(value?: UsagePackageType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customName': value.customName,
        'description': value.description,
        'supportedCountries': value.supportedCountries,
        'packageDuration': value.packageDuration,
        'automaticActivationType': AutomaticActivationTypesToJSON(value.automaticActivationType),
        'dataUsageAllowance': DataUsageAllowanceToJSON(value.dataUsageAllowance),
        'smsUsageAllowance': SmsUsageAllowanceToJSON(value.smsUsageAllowance),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
    };
}

