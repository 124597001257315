/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CdrState } from './CdrState';
import {
    CdrStateFromJSON,
    CdrStateFromJSONTyped,
    CdrStateToJSON,
} from './CdrState';
import type { SmsDirection } from './SmsDirection';
import {
    SmsDirectionFromJSON,
    SmsDirectionFromJSONTyped,
    SmsDirectionToJSON,
} from './SmsDirection';
import type { SmsOrigin } from './SmsOrigin';
import {
    SmsOriginFromJSON,
    SmsOriginFromJSONTyped,
    SmsOriginToJSON,
} from './SmsOrigin';

/**
 * 
 * @export
 * @interface SmsCdr
 */
export interface SmsCdr {
    /**
     * 
     * @type {string}
     * @memberof SmsCdr
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SmsCdr
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsCdr
     */
    billingGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsCdr
     */
    icc: string;
    /**
     * 
     * @type {string}
     * @memberof SmsCdr
     */
    imsi: string;
    /**
     * 
     * @type {string}
     * @memberof SmsCdr
     */
    networkCredentialTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsCdr
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsCdr
     */
    simConfigurationId?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsCdr
     */
    costPlanId?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsCdr
     */
    ratePlanId?: string;
    /**
     * The cost of handling the SMS before applying any markups
     * @type {number}
     * @memberof SmsCdr
     */
    cost?: number;
    /**
     * 
     * @type {SmsDirection}
     * @memberof SmsCdr
     */
    direction: SmsDirection;
    /**
     * 
     * @type {SmsOrigin}
     * @memberof SmsCdr
     */
    origin: SmsOrigin;
    /**
     * 
     * @type {string}
     * @memberof SmsCdr
     */
    plmn: string;
    /**
     * 
     * @type {string}
     * @memberof SmsCdr
     */
    countryCode: string;
    /**
     * 
     * @type {Date}
     * @memberof SmsCdr
     */
    recordedAt: Date;
    /**
     * 
     * @type {CdrState}
     * @memberof SmsCdr
     */
    status?: CdrState;
}

/**
 * Check if a given object implements the SmsCdr interface.
 */
export function instanceOfSmsCdr(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "icc" in value;
    isInstance = isInstance && "imsi" in value;
    isInstance = isInstance && "direction" in value;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "plmn" in value;
    isInstance = isInstance && "countryCode" in value;
    isInstance = isInstance && "recordedAt" in value;

    return isInstance;
}

export function SmsCdrFromJSON(json: any): SmsCdr {
    return SmsCdrFromJSONTyped(json, false);
}

export function SmsCdrFromJSONTyped(json: any, ignoreDiscriminator: boolean): SmsCdr {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'billingGroupId': !exists(json, 'billingGroupId') ? undefined : json['billingGroupId'],
        'icc': json['icc'],
        'imsi': json['imsi'],
        'networkCredentialTypeId': !exists(json, 'networkCredentialTypeId') ? undefined : json['networkCredentialTypeId'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'simConfigurationId': !exists(json, 'simConfigurationId') ? undefined : json['simConfigurationId'],
        'costPlanId': !exists(json, 'costPlanId') ? undefined : json['costPlanId'],
        'ratePlanId': !exists(json, 'ratePlanId') ? undefined : json['ratePlanId'],
        'cost': !exists(json, 'cost') ? undefined : json['cost'],
        'direction': SmsDirectionFromJSON(json['direction']),
        'origin': SmsOriginFromJSON(json['origin']),
        'plmn': json['plmn'],
        'countryCode': json['countryCode'],
        'recordedAt': (new Date(json['recordedAt'])),
        'status': !exists(json, 'status') ? undefined : CdrStateFromJSON(json['status']),
    };
}

export function SmsCdrToJSON(value?: SmsCdr | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerId': value.customerId,
        'billingGroupId': value.billingGroupId,
        'icc': value.icc,
        'imsi': value.imsi,
        'networkCredentialTypeId': value.networkCredentialTypeId,
        'productId': value.productId,
        'simConfigurationId': value.simConfigurationId,
        'costPlanId': value.costPlanId,
        'ratePlanId': value.ratePlanId,
        'cost': value.cost,
        'direction': SmsDirectionToJSON(value.direction),
        'origin': SmsOriginToJSON(value.origin),
        'plmn': value.plmn,
        'countryCode': value.countryCode,
        'recordedAt': (value.recordedAt.toISOString()),
        'status': CdrStateToJSON(value.status),
    };
}

