import React from "react";
import { LoadingSkeleton, PlaceholderText, QueryCell } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import InlineNetworkCredentialType from "../../InlineNetworkCredentialType/InlineNetworkCredentialType";

export interface NetworkCredentialTypeCellProps {
    networkCredentialTypeId?: string;
}

export const NetworkCredentialTypeCell = ({ networkCredentialTypeId }: NetworkCredentialTypeCellProps) => {
    const { productApi } = useApi();
    if (!networkCredentialTypeId) {
        return <PlaceholderText text={null} />;
    }

    return (
        <QueryCell
            loadingComponent={<LoadingSkeleton />}
            query={{
                queryFn: () =>
                    productApi.getNetworkCredentialType({ networkCredentialTypeId: networkCredentialTypeId }),
                queryKey: ["networkcredentialtypes", networkCredentialTypeId],
            }}
            render={result => <InlineNetworkCredentialType networkCredentialType={result.data} />}
        />
    );
};
