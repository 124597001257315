import React, { useMemo } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ControlledAutoCompleteSingleSelectInput, FormContainer, FormField } from "@cobira/ui-library";
import { Button, ButtonGroup, Input } from "@chakra-ui/react";
import { Customer } from "../../api";
import { useApi } from "../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";

const AddPackageToCatalogueFormSchema = z.object({
    usagePackageType: z.object({
        id: z.string(),
        customName: z.string(),
    }),
    catalogueName: z.string().min(1, "Please specify a name"),
    catalogueDescription: z.string().optional(),
});

export type AddPackageToCatalogueFormSchemaType = z.infer<typeof AddPackageToCatalogueFormSchema>;

export interface AddPackageToCatalogueFormProps {
    customer: Customer;
    onSubmit: (form: AddPackageToCatalogueFormSchemaType) => void;
    onCancel?: () => void;
}

const AddPackageToCatalogueForm = ({ customer, onSubmit, onCancel }: AddPackageToCatalogueFormProps) => {
    const { usagePackageApi } = useApi();
    const {
        formState: { errors },
        handleSubmit,
        control,
        register,
    } = useForm<AddPackageToCatalogueFormSchemaType>({
        resolver: zodResolver(AddPackageToCatalogueFormSchema),
    });

    const { data: customerUsagePackageTypes } = useQuery(["customers", customer.id, "usagepackagetypes"], () =>
        usagePackageApi.getCustomerUsagePackageTypes({ customerId: customer.id, pageNumber: 0, pageSize: 9999 }),
    );

    const { data: usagePackageTypes } = useQuery(["usagepackagetypes", "deleted=false"], () =>
        usagePackageApi.getUsagePackageTypes({ includeDeleted: false, pageNumber: 0, pageSize: 9999 }),
    );

    const availableUsagePackageTypes = useMemo(() => {
        if (customerUsagePackageTypes && usagePackageTypes) {
            return usagePackageTypes?.content.filter(
                usagePackageType =>
                    !customerUsagePackageTypes?.content.find(
                        customerUsagePackageType => customerUsagePackageType.id === usagePackageType.id,
                    ),
            );
        }

        return [];
    }, [customerUsagePackageTypes, usagePackageTypes]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Package"} error={errors?.usagePackageType}>
                    <ControlledAutoCompleteSingleSelectInput
                        control={{ control: control, name: "usagePackageType" }}
                        input={{
                            items: availableUsagePackageTypes,
                            isClearable: true,
                            placeholder: "Select Package",
                            displayMapping: value => value.customName,
                            autocompleteAbleMapping: value => value.customName,
                        }}
                    />
                </FormField>
                <FormField label={"Override Name"} error={errors?.catalogueName}>
                    <Input {...register("catalogueName")} />
                </FormField>
                <FormField label={"Override Description"} error={errors?.catalogueDescription}>
                    <Input {...register("catalogueDescription")} />
                </FormField>
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Create
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default AddPackageToCatalogueForm;
