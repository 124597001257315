import React from "react";
import { Authentication, MainLayout, useCobiraEnvironment } from "@cobira/ui-library";
import SimCardListPage from "./pages/simcard/SimCardListPage/SimCardListPage";
import { Auth0Provider } from "@auth0/auth0-react";
import { Navigate, Route, Routes } from "react-router";
import SimCardDetailPage from "./pages/simcard/SimCardDetailPage/SimCardDetailPage";
import CustomerListPage from "./pages/customer/CustomerListPage/CustomerListPage";
import CustomerDetailPage from "./pages/customer/CustomerDetailPage/CustomerDetailPage";
import BillingGroupDetailPage from "./pages/billinggroup/BillingGroupDetailPage/BillingGroupDetailPage";
import NetworkCredentialTypeListPage from "./pages/networkcredentialtype/NetworkCredentialTypeListPage/NetworkCredentialTypeListPage";
import NetworkCredentialTypeDetailPage from "./pages/networkcredentialtype/NetworkCredentialTypeDetailPage/NetworkCredentialTypeDetailPage";
import CostPlanDetailPage from "./pages/networkcredentialtype/CostPlanDetailPage/CostPlanDetailPage";
import CustomerInvoiceDetailPage from "./pages/customer/CustomerInvoiceDetailPage/CustomerInvoiceDetailPage";
import MvnoActionListPage from "./pages/action/MvnoActionListPage/MvnoActionListPage";
import UsagePackageTypeDetailPage from "./pages/usagepackagetype/UsagePackageTypeDetailPage/UsagePackageTypeDetailPage";
import IntegrationListPage from "./pages/integration/IntegrationListPage/IntegrationListPage";
import IntegrationDetailPage from "./pages/integration/IntegrationDetailPage/IntegrationDetailPage";
import TenantListPage from "./pages/tenant/TenantListPage/TenantListPage";
import TenantDetailPage from "./pages/tenant/TenantDetailPage/TenantDetailPage";
import CdrListPage from "./pages/cdr/CdrListPage/CdrListPage";
import DataCdrDetailPage from "./pages/cdr/DataCdrDetailPage/DataCdrDetailPage";
import SmsCdrDetailPage from "./pages/cdr/SmsCdrDetailPage/SmsCdrDetailPage";
import SimCardDataCdrDetailPage from "./pages/simcard/SimCardDetailPage/Cdr/SimCardDataCdrDetailPage";
import SimCardSmsCdrDetailPage from "./pages/simcard/SimCardDetailPage/Cdr/SimCardSmsCdrDetailPage";
import RatePlanDetailPage from "./pages/rateplan/RatePlanDetailPage/RatePlanDetailPage";
import RatePlanListPage from "./pages/rateplan/RatePlanListPage/RatePlanListPage";
import RatePlanCreatePage from "./pages/rateplan/RatePlanCreatePage/RatePlanCreatePage";
import RatePlanUpdatePage from "./pages/rateplan/RatePlanUpdatePage/RatePlanUpdatePage";
import CustomerRecurringFeeDetailPage from "./pages/customer/CustomerRecurringFeeDetailPage/CustomerRecurringFeeDetailPage";
import UserListPage from "./pages/user/UserListPage/UserListPage";
import TenantCostPlanDetailPage from "./pages/tenant/TenantCostPlanDetailPage/TenantCostPlanDetailPage";
import TenantNetworkCredentialTypeDetailPage from "./pages/tenant/TenantNetworkCredentialTypeDetailPage/TenantNetworkCredentialTypeDetailPage";
import TenantSimConfigurationDetailPage from "./pages/tenant/TenantSimConfigurationDetailPage/TenantSimConfigurationDetailPage";
import SimConfigurationListPage from "./pages/simconfiguration/SimConfigurationListPage/SimConfigurationListPage";
import SimConfigurationDetailPage from "./pages/simconfiguration/SimConfigurationDetailPage/SimConfigurationDetailPage";
import MvnoDebugPage from "./pages/debug/MvnoDebugPage/MvnoDebugPage";
import MvnoActionDetailPage from "./pages/action/MvnoActionDetailsPage/MvnoActionDetailPage";
import MvnoSidebar from "./components/MvnoSidebar/MvnoSidebar";
import DashboardsPage from "./pages/dashboards/DashboardsPage/DashboardsPage";
import ConfigurationPage from "./pages/configuration/ConfigurationPage";
import BillingPage from "./pages/billing/BillingPage";
import TenantUsagePackageTypeDetailPage from "./pages/tenant/TenantUsagePackageTypeDetailPage/TenantUsagePackageTypeDetailPage";
import CustomerCompareDashboardPage from "./pages/dashboards/CustomerCompareDashboardPage/CustomerCompareDashboardPage";

function App() {
    const [, environment] = useCobiraEnvironment();

    return (
        <Auth0Provider
            domain={environment.auth.domain}
            clientId={environment.auth.clientId}
            cacheLocation={"localstorage"}
            useRefreshTokens={true}
            authorizationParams={{
                audience: environment.auth.audience,
                redirect_uri: environment.auth.redirectUrl,
                scope: "openid email profile offline_access",
            }}
        >
            <Authentication>
                <MainLayout sidebar={<MvnoSidebar />}>
                    <Routes>
                        <Route path="/debug" element={<MvnoDebugPage />} />
                        <Route path="/actions" element={<MvnoActionListPage />} />
                        <Route path="/actions/:actionId" element={<MvnoActionDetailPage />} />
                        <Route path="/simcards" element={<SimCardListPage />} />
                        <Route path="/simcards/:icc" element={<SimCardDetailPage />} />
                        <Route path="/simcards/:icc/cdr/data/:cdrId" element={<SimCardDataCdrDetailPage />} />
                        <Route path="/simcards/:icc/cdr/sms/:cdrId" element={<SimCardSmsCdrDetailPage />} />
                        <Route path="/customers" element={<CustomerListPage />} />
                        <Route path="/customers/:customerId" element={<CustomerDetailPage />} />
                        <Route
                            path="/customers/:customerId/billinggroups/:billingGroupId"
                            element={<BillingGroupDetailPage />}
                        />
                        <Route
                            path="/customers/:customerId/invoices/:invoiceId"
                            element={<CustomerInvoiceDetailPage />}
                        />
                        <Route
                            path="/customers/:customerId/recurringfees/:customerRecurringFeeId"
                            element={<CustomerRecurringFeeDetailPage />}
                        />
                        <Route path="/networkcredentialtypes" element={<NetworkCredentialTypeListPage />} />
                        <Route
                            path="/networkcredentialtypes/:networkCredentialTypeId"
                            element={<NetworkCredentialTypeDetailPage />}
                        />
                        <Route
                            path="/networkcredentialtypes/:networkCredentialTypeId/costplans/:costPlanId"
                            element={<CostPlanDetailPage />}
                        />
                        <Route path="/simconfigurations" element={<SimConfigurationListPage />} />
                        <Route path="/simconfigurations/:simConfigurationId" element={<SimConfigurationDetailPage />} />
                        <Route
                            path="/configuration/usagepackagetypes/:usagePackageTypeId"
                            element={<UsagePackageTypeDetailPage />}
                        />
                        <Route path="/integrations" element={<IntegrationListPage />} />
                        <Route path="/integrations/:integrationId" element={<IntegrationDetailPage />} />
                        <Route path="/tenants" element={<TenantListPage />} />
                        <Route path="/tenants/:tenantId" element={<TenantDetailPage />} />
                        <Route
                            path="/tenants/:tenantId/networkcredentialtypes/:networkCredentialTypeId"
                            element={<TenantNetworkCredentialTypeDetailPage />}
                        />
                        <Route
                            path="/tenants/:tenantId/networkcredentialtypes/:networkCredentialTypeId/costplans/:costPlanId"
                            element={<TenantCostPlanDetailPage />}
                        />
                        <Route
                            path="/tenants/:tenantId/simconfigurations/:simConfigurationId"
                            element={<TenantSimConfigurationDetailPage />}
                        />
                        <Route
                            path="/tenants/:tenantId/usagepackagetypes/:usagePackageTypeId"
                            element={<TenantUsagePackageTypeDetailPage />}
                        />
                        <Route path="/cdr" element={<CdrListPage />} />
                        <Route path="/cdr/data/:cdrId" element={<DataCdrDetailPage />} />
                        <Route path="/cdr/sms/:cdrId" element={<SmsCdrDetailPage />} />
                        <Route path="/rateplans" element={<RatePlanListPage />} />
                        <Route path="/rateplans/create" element={<RatePlanCreatePage />} />
                        <Route path="/rateplans/:ratePlanId" element={<RatePlanDetailPage />} />
                        <Route path="/rateplans/:ratePlanId/update" element={<RatePlanUpdatePage />} />
                        <Route path="/team" element={<UserListPage />} />
                        <Route path="/configuration" element={<ConfigurationPage />} />
                        <Route path="/billing" element={<BillingPage />} />
                        <Route path="/" element={<Navigate to={"/dashboards/account"} />} />
                        <Route path="/dashboards/account" element={<DashboardsPage />} />
                        <Route path="/dashboards/customer-compare" element={<CustomerCompareDashboardPage />} />
                    </Routes>
                </MainLayout>
            </Authentication>
        </Auth0Provider>
    );
}

export default App;
