import React from "react";
import { TabPanel } from "@chakra-ui/react";
import { DataCdr, SimCard } from "../../../../api";
import { useQuery } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useApi } from "../../../../hooks/useApi";
import {
    CobiraTable,
    CountryInfo,
    DataText,
    DateTime,
    getCountry,
    SlimUuid,
    usePageNavigation,
    usePagination,
} from "@cobira/ui-library";
import NetworkInfo from "../../../../components/NetworkInfo/NetworkInfo";
import { createColumnHelper } from "@tanstack/react-table";

interface SimCardDataSessionsPanelProps {
    query: UseQueryResult<SimCard>;
}

const dataSessionColumn = createColumnHelper<DataCdr>();
const COLUMNS = [
    dataSessionColumn.accessor("imsi", {
        id: "imsi",
        header: "IMSI",
        cell: row => row.getValue(),
    }),
    dataSessionColumn.accessor("plmn", {
        id: "plmn",
        header: "Network",
        cell: row => <NetworkInfo plmn={row.getValue()} />,
    }),
    dataSessionColumn.accessor("countryCode", {
        id: "country",
        header: "Country",
        cell: row => <CountryInfo country={getCountry(row.getValue())} />,
    }),
    dataSessionColumn.accessor("openedAt", {
        id: "openedAt",
        header: "Opened At",
        cell: row => <DateTime value={row.getValue()} />,
    }),
    dataSessionColumn.accessor("closedAt", {
        id: "closedAt",
        header: "Closed At",
        cell: row => <DateTime value={row.getValue()} />,
    }),
    dataSessionColumn.accessor("sessionId", {
        id: "identifier",
        header: "SessionId",
        cell: row => <SlimUuid uuid={row.getValue() || ""} />,
    }),
    dataSessionColumn.accessor("billableBytes", {
        id: "totalBytes",
        header: "Total Usage",
        cell: row => <DataText bytes={row.getValue()} decimals={2} />,
    }),
];

const SimCardDataSessionsPanel = ({ query: { data: value } }: SimCardDataSessionsPanelProps) => {
    const { cdrApi } = useApi();
    const pageNavigation = usePageNavigation<DataCdr>({
        route: value => `/simcards/${value.icc}/cdr/data/${value.id}`,
    });
    const { pageState, setPageState } = usePagination({
        pageIndex: 0,
        pageSize: 20,
    });

    const { data: dataSessions, isLoading: isLoadingDataSessions } = useQuery(
        ["simcards", value?.icc, "datasessions", pageState],
        () => {
            const now = new Date();
            const oneMonthAgo = new Date(now);
            oneMonthAgo.setMonth(now.getMonth() - 1);
            return cdrApi.getDataCdr({
                icc: value?.icc || "",
                pageNumber: pageState.pageIndex,
                pageSize: pageState.pageSize,
                fromDate: oneMonthAgo,
                toDate: now,
            });
        },
        { enabled: value?.icc !== undefined },
    );

    return (
        <TabPanel w={"100%"}>
            <CobiraTable
                columns={COLUMNS}
                data={dataSessions?.content || []}
                isLoading={isLoadingDataSessions}
                withPagination={{
                    pageSize: pageState.pageSize,
                    pageIndex: pageState.pageIndex,
                    onPaginationChange: setPageState,
                    totalRowCount: dataSessions?.pageProperties?.totalElements || 0,
                    totalPageCount: dataSessions?.pageProperties?.totalPages || 0,
                }}
                withRowClick={{
                    enableHoverStyle: true,
                    onRowClicked: pageNavigation.navigate,
                }}
            />
        </TabPanel>
    );
};

export default SimCardDataSessionsPanel;
