import React from "react";
import { z } from "zod";
import { Currency, RecurringFeeContext, RecurringFeePricingStrategy } from "../../api";
import { ControlledAutoCompleteSingleSelectInput, FormContainer, FormField, Month } from "@cobira/ui-library";
import { Control, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, ButtonGroup, HStack, Input } from "@chakra-ui/react";
import { ControlledMonthInput } from "../../components/Input/ControlledMonthInput/ControlledMonthInput";
import { CurrencyLabels } from "../../labels/CurrencyLabels";
import { CURRENCY_OPTIONS } from "../../utils/pricingUtils";
import { ControlledBillingGroupSelectInput } from "../../components/Input/ControlledBillingGroupSelectInput/ControlledBillingGroupSelectInput";
import CustomerRecurringFeePricingInput, {
    CustomerRecurringFeePricingFormSchema,
} from "./CustomerRecurringFeePricingInput";
import { RecurringFeeContextLabels } from "../../labels/RecurringFeeContextLabels";
import { RecurringFeePricingStrategyLabels } from "../../labels/RecurringFeePricingStrategyLabels";
import ConsumptionBasedPricingExplanation from "./ConsumptionBasedPricingExplanation";

export const CONSUMPTION_BASED_CONTEXT_OPTIONS = [
    RecurringFeeContext.ActiveSim,
    RecurringFeeContext.ActiveImsi,
    RecurringFeeContext.InventorySim,
] as const;

export const CONSUMPTION_BASED_STRATEGY_OPTIONS = [
    RecurringFeePricingStrategy.Volume,
    RecurringFeePricingStrategy.Tiered,
] as const;

const CreateConsumptionBasedCustomerRecurringFeeFormSchema = z
    .object({
        name: z.string().min(1, "Name must be specified"),
        anniversary: z.object({
            year: z.number().min(2020, "Cannot create fees with anniversary before 2020"),
            month: z.nativeEnum(Month),
        }),
        recurrence: z.number().min(1, "Fee must recur at least once a month"),
        currency: z.nativeEnum(Currency),
        context: z.enum(CONSUMPTION_BASED_CONTEXT_OPTIONS),
        strategy: z.enum(CONSUMPTION_BASED_STRATEGY_OPTIONS),
        billingGroups: z
            .array(
                z.object({
                    name: z.string(),
                    id: z.string(),
                }),
            )
            .optional(),
    })
    .merge(CustomerRecurringFeePricingFormSchema);

export type CreateConsumptionBasedCustomerRecurringFeeFormType = z.infer<
    typeof CreateConsumptionBasedCustomerRecurringFeeFormSchema
>;

export interface CreateConsumptionBasedCustomerRecurringFeeFormProps {
    customerId: string;
    onSubmit: (form: CreateConsumptionBasedCustomerRecurringFeeFormType) => void;
    submitting?: boolean;
    onCancel?: () => void;
}

const CreateConsumptionBasedCustomerRecurringFeeForm = ({
    customerId,
    onSubmit,
    submitting,
    onCancel,
}: CreateConsumptionBasedCustomerRecurringFeeFormProps) => {
    const {
        formState: { errors },
        handleSubmit,
        watch,
        control,
        register,
    } = useForm<CreateConsumptionBasedCustomerRecurringFeeFormType>({
        resolver: zodResolver(CreateConsumptionBasedCustomerRecurringFeeFormSchema),
        defaultValues: {
            anniversary: {
                year: new Date().getUTCFullYear(),
                month: Object.values(Month)[new Date().getUTCMonth()],
            },
            recurrence: 1,
            currency: "EUR",
            priceBounds: [
                {
                    price: 0,
                    lowerBound: 1,
                },
            ],
        },
    });

    const context = watch("context");
    const strategy = watch("strategy");
    const currency = watch("currency");

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Name"} error={errors?.name}>
                    <Input {...register("name")} />
                </FormField>
                <HStack w={"100%"} gap={2}>
                    <FormField label={"Anniversary Year"} error={errors?.anniversary?.year}>
                        <Input {...register("anniversary.year", { valueAsNumber: true })} />
                    </FormField>
                    <FormField label={"Anniversary Month"} error={errors?.anniversary?.month}>
                        <ControlledMonthInput control={control} name={"anniversary.month"} />
                    </FormField>
                </HStack>
                <HStack w={"100%"} gap={2}>
                    <FormField label={"Months between recurrence"} error={errors?.recurrence}>
                        <Input {...register("recurrence", { valueAsNumber: true })} />
                    </FormField>
                    <FormField label={"Currency"} error={errors?.currency}>
                        <ControlledAutoCompleteSingleSelectInput
                            control={{
                                control: control,
                                name: "currency",
                            }}
                            input={{
                                items: CURRENCY_OPTIONS,
                                placeholder: "Select Currency",
                                displayMapping: value => CurrencyLabels[value],
                                autocompleteAbleMapping: value => CurrencyLabels[value],
                            }}
                        />
                    </FormField>
                </HStack>
                <HStack w={"100%"} gap={2}>
                    <FormField label={"Fee Context"} error={errors?.context}>
                        <ControlledAutoCompleteSingleSelectInput
                            control={{
                                control: control,
                                name: "context",
                            }}
                            input={{
                                items: [...CONSUMPTION_BASED_CONTEXT_OPTIONS],
                                placeholder: "Select Context",
                                displayMapping: value => RecurringFeeContextLabels[value],
                                autocompleteAbleMapping: value => RecurringFeeContextLabels[value],
                            }}
                        />
                    </FormField>
                    <FormField label={"Rating Behaviour"} error={errors?.strategy}>
                        <ControlledAutoCompleteSingleSelectInput
                            control={{
                                control: control,
                                name: "strategy",
                            }}
                            input={{
                                items: [...CONSUMPTION_BASED_STRATEGY_OPTIONS],
                                placeholder: "Select Rating Behaviour",
                                displayMapping: value => RecurringFeePricingStrategyLabels[value],
                                autocompleteAbleMapping: value => RecurringFeePricingStrategyLabels[value],
                            }}
                        />
                    </FormField>
                </HStack>
                <FormField label={"Limit fee to certain billing groups"} error={errors?.billingGroups}>
                    <ControlledBillingGroupSelectInput
                        control={{ control: control, name: "billingGroups" }}
                        input={{ isMulti: true }}
                        customerIdConstraint={customerId}
                    />
                </FormField>
                {context && strategy && <ConsumptionBasedPricingExplanation context={context} />}
                {context && strategy && (
                    <CustomerRecurringFeePricingInput
                        context={context}
                        currency={currency}
                        control={
                            control as Control<
                                Partial<CreateConsumptionBasedCustomerRecurringFeeFormType> &
                                    Pick<
                                        CreateConsumptionBasedCustomerRecurringFeeFormType,
                                        "priceBounds" | "minimumPrice"
                                    >
                            >
                        }
                        errors={errors}
                    />
                )}
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"} isLoading={submitting} isDisabled={submitting}>
                    Create
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"} isDisabled={submitting}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default CreateConsumptionBasedCustomerRecurringFeeForm;
