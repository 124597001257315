import { Customer } from "../../../../api";
import { Grid } from "@chakra-ui/react";
import { Paper, PlaceholderText, TextColumn } from "@cobira/ui-library";
import React from "react";

export interface CustomerSummaryPaperProps {
    customer?: Customer;
    isLoading?: boolean;
}

const CustomerSummaryPaper = ({ customer, isLoading }: CustomerSummaryPaperProps) => {
    return (
        <Paper header={"Summary"} withDivider>
            <Grid templateColumns="auto auto auto" gap="4">
                <TextColumn heading="Name" grid isLoading={isLoading}>
                    {customer?.customerName}
                </TextColumn>
                <TextColumn heading="Contact Name" grid isLoading={isLoading}>
                    <PlaceholderText text={customer?.contactName} />
                </TextColumn>
                <TextColumn heading="Account Number" grid isLoading={isLoading}>
                    <PlaceholderText text={customer?.accountNumber} />
                </TextColumn>
                <TextColumn heading="Shipping Address" grid isLoading={isLoading}>
                    <PlaceholderText text={customer?.shippingAddress} />
                </TextColumn>
                <TextColumn heading="Contact Email" grid isLoading={isLoading}>
                    <PlaceholderText text={customer?.contactEmail} />
                </TextColumn>
            </Grid>
        </Paper>
    );
};

export default CustomerSummaryPaper;
