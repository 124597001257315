import React, { useMemo } from "react";
import {
    CobiraTable,
    SearchBar,
    SearchSelectInput,
    SearchSingleInput,
    singleInput,
    useSearch,
} from "@cobira/ui-library";
import { CostPlan, TelcoCost, TelcoCostPage, TelcoServiceType } from "../../api";
import { TelcoServiceTypeLabels } from "../../labels/TelcoServiceTypeLabels";
import { VStack } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import NetworkInfo from "../NetworkInfo/NetworkInfo";
import { UseQueryResult } from "@tanstack/react-query";
import TelcoCostPrice from "../TelcoCostPrice/TelcoCostPrice";

const telcoCostColumn = createColumnHelper<TelcoCost>();
const COLUMNS = (costPlan?: CostPlan) => [
    telcoCostColumn.accessor("key", {
        id: "key",
        header: "Network",
        cell: row => <NetworkInfo plmn={row.getValue()} />,
    }),
    telcoCostColumn.accessor(row => row, {
        id: "cost",
        header: "Cost",
        cell: row => (
            <TelcoCostPrice
                cost={row.getValue().pricePerUnit}
                currency={costPlan?.currency || "UNDEFINED"}
                serviceType={row.getValue().serviceType}
            />
        ),
    }),
    telcoCostColumn.accessor("serviceType", {
        id: "serviceType",
        header: "Service",
        cell: row => TelcoServiceTypeLabels[row.getValue()],
    }),
];

export interface TelcoCostTableWithSearchProps {
    costPlan?: CostPlan;
    telcoCostsQuery: UseQueryResult<TelcoCostPage>;
}

const TelcoCostTableWithSearch = ({ costPlan, telcoCostsQuery }: TelcoCostTableWithSearchProps) => {
    const columns = useMemo(() => COLUMNS(costPlan), [costPlan]);
    const { data: telcoCosts, isLoading: isLoadingTelcoCosts } = telcoCostsQuery;

    const search = useSearch({
        config: {
            plmn: singleInput("PLMN"),
            service: singleInput<TelcoServiceType>("Service", { display: value => TelcoServiceTypeLabels[value] }),
        },
    });

    const filteredTelcoCosts = useMemo(() => {
        const plmnSearch = search.state?.plmn?.value;
        const serviceSearch = search.state?.service?.value;
        return telcoCosts?.content
            ?.filter(cost => (!!plmnSearch && cost.key.includes(plmnSearch)) || !plmnSearch)
            ?.filter(cost => (!!serviceSearch && cost.serviceType === serviceSearch) || !serviceSearch);
    }, [telcoCosts, search.state]);

    return (
        <VStack w={"100%"} gap={2}>
            <SearchBar
                useSearch={search}
                defaultFilterInputId={"plmn"}
                filterInputs={[
                    {
                        id: "plmn",
                        menuLabel: "PLMN",
                        inputComponent: (
                            <SearchSingleInput
                                registration={search.registerInput({ id: "plmn" })}
                                placeholder={"Search by PLMN"}
                            />
                        ),
                    },
                    {
                        id: "service",
                        menuLabel: "Service",
                        inputComponent: (
                            <SearchSelectInput
                                registration={search.registerInput({ id: "service" })}
                                items={Object.values(TelcoServiceType)}
                                displayMapping={value => TelcoServiceTypeLabels[value as TelcoServiceType]}
                                autocompleteAbleMapping={value => TelcoServiceTypeLabels[value as TelcoServiceType]}
                                placeholder={"Search by Service"}
                            />
                        ),
                    },
                ]}
            />
            <CobiraTable
                columns={columns}
                data={filteredTelcoCosts || []}
                isLoading={isLoadingTelcoCosts}
                withPagination
            />
        </VStack>
    );
};

export default TelcoCostTableWithSearch;
