import React, { useMemo } from "react";
import { CobiraTable, SearchBar, SearchSingleInput, singleInput, useSearch } from "@cobira/ui-library";
import { CostPlan, NetworkAccessCost, NetworkAccessCostPage } from "../../api";
import { VStack } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import NetworkInfo from "../NetworkInfo/NetworkInfo";
import { UseQueryResult } from "@tanstack/react-query";
import { CurrencyLabels } from "../../labels/CurrencyLabels";

const networkAccessCostColumn = createColumnHelper<NetworkAccessCost>();
const COLUMNS = (costPlan?: CostPlan) => [
    networkAccessCostColumn.accessor("plmn", {
        id: "plmn",
        header: "Network",
        cell: row => <NetworkInfo plmn={row.getValue()} />,
    }),
    networkAccessCostColumn.accessor("cost", {
        id: "cost",
        header: "Cost",
        cell: row => `${row.getValue().toFixed(4)} ${CurrencyLabels[costPlan?.currency || "UNDEFINED"]} / IMSI`,
    }),
];

export interface NetworkAccessCostTableWithSearchProps {
    costPlan?: CostPlan;
    networkAccessCostsQuery: UseQueryResult<NetworkAccessCostPage>;
}

const NetworkAccessCostTableWithSearch = ({
    costPlan,
    networkAccessCostsQuery,
}: NetworkAccessCostTableWithSearchProps) => {
    const { data: networkAccessCosts, isLoading } = networkAccessCostsQuery;
    const search = useSearch({
        config: {
            plmn: singleInput("PLMN"),
        },
    });

    const columns = useMemo(() => COLUMNS(costPlan), [costPlan]);
    const filteredNetworkAccessCosts = useMemo(() => {
        const plmnSearch = search.state?.plmn?.value;
        return networkAccessCosts?.content?.filter(
            cost => (!!plmnSearch && cost.plmn.includes(plmnSearch)) || !plmnSearch,
        );
    }, [networkAccessCosts, search.state]);

    return (
        <VStack w={"100%"} gap={2}>
            <SearchBar
                useSearch={search}
                defaultFilterInputId={"plmn"}
                filterInputs={[
                    {
                        id: "plmn",
                        menuLabel: "PLMN",
                        inputComponent: (
                            <SearchSingleInput
                                registration={search.registerInput({ id: "plmn" })}
                                placeholder={"Search by PLMN"}
                            />
                        ),
                    },
                ]}
            />
            <CobiraTable
                columns={columns}
                data={filteredNetworkAccessCosts || []}
                isLoading={isLoading}
                withPagination
            />
        </VStack>
    );
};

export default NetworkAccessCostTableWithSearch;
