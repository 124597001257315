import { WebhookSubscriptionType } from "../api";

export const WebhookSubscriptionTypeLabels = {
    [WebhookSubscriptionType.SimCardUpdated]: "SIM Card updated",
    [WebhookSubscriptionType.SimCardDeleted]: "SIM Card deleted",
    [WebhookSubscriptionType.NetworkCredentialUpdated]: "Network Credential updated",
    [WebhookSubscriptionType.ActionUpdated]: "Action updated",
    [WebhookSubscriptionType.ActionItemUpdated]: "Action Item updated",
    [WebhookSubscriptionType.LocationUpdate]: "Location Update",
    [WebhookSubscriptionType.SessionUsage]: "Session Usage",
    [WebhookSubscriptionType.BillingGroupUpdated]: "Billing Group updated",
    [WebhookSubscriptionType.CustomerUpdated]: "Customer updated",
    [WebhookSubscriptionType.BillingGroupDataUsage]: "Billing Group Data Usage",
    [WebhookSubscriptionType.BillingGroupSmsUsage]: "Billing Group SMS Usage",
    [WebhookSubscriptionType.PhoneNumberUpdated]: "Phone Number updated",
    [WebhookSubscriptionType.SimConfigurationUpdated]: "SIM Configuration updated",
    [WebhookSubscriptionType.SimProductUpdated]: "SIM Product updated",
    [WebhookSubscriptionType.UsagePackageTypeUpdated]: "Usage Package Type updated",
    [WebhookSubscriptionType.UsagePackageUpdated]: "Usage Package updated",
    [WebhookSubscriptionType.UsagePackageCatalogueUpdated]: "Usage Package Catalogue updated",
    [WebhookSubscriptionType.SimCardNetworkStateUpdated]: "SIM Network State updated",
    [WebhookSubscriptionType.NetworkUpdated]: "Network updated",
    [WebhookSubscriptionType.CustomerContactUpdated]: "Customer Contact updated",
    [WebhookSubscriptionType.ImeiLockUpdated]: "IMEI Lock updated",
    [WebhookSubscriptionType.NetworkCredentialSettingsUpdated]: "Network Credential Settings updated",
    [WebhookSubscriptionType.ImeiLockViolated]: "IMEI lock violated",
    [WebhookSubscriptionType.Unknown]: "Unknown",
    [WebhookSubscriptionType.HardwareUpdated]: "Hardware updated",
    [WebhookSubscriptionType.EsimUpdated]: "ESIM updated",
};
