import React from "react";
import {
    CobiraTable,
    PageLayout,
    PageTitle,
    PlaceholderText,
    SearchBar,
    SearchSingleInput,
    singleInput,
    usePageNavigation,
    useSearch,
    useUrlPagination,
} from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { Customer } from "../../../api";
import { useQuery } from "@tanstack/react-query";
import { Button, useBoolean, VStack } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import CreateCustomerModal from "../../../components/Modal/CreateCustomerModal/CreateCustomerModal";
import {
    ConsumptionStateSelect,
    SearchSelectConsumptionState,
} from "../../../components/SearchSelectConsumptionState/SearchSelectConsumptionState";

const customerColumn = createColumnHelper<Customer>();
const COLUMNS = [
    customerColumn.accessor("customerName", {
        id: "customerName",
        header: "Name",
        cell: row => row.getValue(),
    }),
    customerColumn.accessor("shippingAddress", {
        id: "shippingAddress",
        header: "Shipping Address",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    customerColumn.accessor("contactName", {
        id: "contactName",
        header: "Contact Name",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    customerColumn.accessor("contactEmail", {
        id: "contactEmail",
        header: "Contact Email",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    customerColumn.accessor("accountNumber", {
        id: "accountNumber",
        header: "Account Number",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
];

const CustomerListPage = () => {
    const { customerApi } = useApi();
    const { navigate } = usePageNavigation<Customer>({ route: value => `/customers/${value.id}` });
    const [isCreatingCustomer, { on, off }] = useBoolean(false);
    const { pageState, setPageState, resetPageState } = useUrlPagination({
        pageIndex: 0,
        pageSize: 20,
    });
    const search = useSearch({
        config: {
            search: singleInput<string>("Search"),
            consumptionState: singleInput<ConsumptionStateSelect>("Usage Activity", { display: value => value.label }),
        },
        onChange: resetPageState,
    });
    const { state } = search;

    const { data: customers, isLoading } = useQuery(["customers", pageState, state], () => {
        return customerApi.getCustomers({
            pageNumber: pageState.pageIndex,
            pageSize: pageState.pageSize,
            search: state?.search?.value || undefined,
            consumptionState: state?.consumptionState?.value?.value || undefined,
        });
    });

    return (
        <>
            <PageLayout
                title={<PageTitle title="Customers" />}
                pageContext={<Button onClick={on}>Create Customer</Button>}
            >
                <VStack w={"100%"} gap={2}>
                    <SearchBar
                        defaultFilterInputId={"search"}
                        useSearch={search}
                        filterInputs={[
                            {
                                id: "search",
                                menuLabel: "Search",
                                inputComponent: (
                                    <SearchSingleInput
                                        registration={search.registerInput({ id: "search" })}
                                        placeholder={"Search"}
                                    />
                                ),
                            },
                            {
                                id: "consumptionState",
                                menuLabel: "Usage Activity",
                                inputComponent: (
                                    <SearchSelectConsumptionState
                                        registration={search.registerInput({ id: "consumptionState" })}
                                    />
                                ),
                            },
                        ]}
                    />
                    <CobiraTable
                        sizing={"fit-page"}
                        columns={COLUMNS}
                        data={customers?.content || []}
                        isLoading={isLoading}
                        withPagination={{
                            pageIndex: pageState.pageIndex,
                            pageSize: pageState.pageSize,
                            onPaginationChange: setPageState,
                            totalRowCount: customers?.pageProperties?.totalElements || 0,
                            totalPageCount: customers?.pageProperties?.totalPages || 0,
                        }}
                        withRowClick={{
                            enableHoverStyle: true,
                            onRowClicked: navigate,
                        }}
                    />
                </VStack>
                <CreateCustomerModal isOpen={isCreatingCustomer} onClose={off} />
            </PageLayout>
        </>
    );
};

export default CustomerListPage;
