import React, { useMemo } from "react";
import {
    Country,
    CountryInfo,
    getCountries,
    SearchBar,
    SearchSelectInput,
    SearchSingleInput,
    singleInput,
    usePageNavigation,
    usePersistedSearch,
    useUrlPagination,
} from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { UsagePackageType } from "../../../api";
import { useQuery } from "@tanstack/react-query";
import { TabPanel, VStack } from "@chakra-ui/react";
import UsagePackageTypeTable from "../../../components/UsagePackageTypeTable/UsagePackageTypeTable";
import { BooleanLabels } from "../../../labels/BooleanLabels";
import { mapBooleanFilter } from "../../../utils/mapperUtils";

const UsagePackageTypeListPanel = () => {
    const { usagePackageApi } = useApi();
    const { navigate } = usePageNavigation<UsagePackageType>({
        route: value => `/configuration/usagepackagetypes/${value.id}`,
    });
    const countries = useMemo(() => getCountries(), []);

    const { setPageState, resetPageState, pageState } = useUrlPagination({ pageIndex: 0, pageSize: 20 });
    const search = usePersistedSearch({
        persistenceKey: "usage-package-type-search",
        config: {
            search: singleInput<string>("Search"),
            country: singleInput<Country>("Country", { display: value => `${value.name} (${value.iso2})` }),
            includeDeleted: singleInput<boolean>("Include Deleted", {
                display: value => BooleanLabels(value),
            }),
            dataPackageEnabled: singleInput<boolean>("Data Enabled", {
                display: value => BooleanLabels(value),
            }),
            smsPackageEnabled: singleInput<boolean>("SMS Enabled", {
                display: value => BooleanLabels(value),
            }),
            assigned: singleInput<boolean>("Assigned to SIM", {
                display: value => BooleanLabels(value),
            }),
            inCatalogue: singleInput<boolean>("Assigned to Catalogue", {
                display: value => BooleanLabels(value),
            }),
        },
        onChange: resetPageState,
    });

    const usagePackageTypesQuery = useQuery(["usagepackagetypes", pageState, search.state], () => {
        return usagePackageApi.getUsagePackageTypes({
            pageNumber: pageState.pageIndex,
            pageSize: pageState.pageSize,
            search: search.state?.search?.value || undefined,
            country: search.state?.country?.value?.iso2 || undefined,
            includeDeleted: mapBooleanFilter(search.state?.includeDeleted?.value),
            dataPackageEnabled: mapBooleanFilter(search.state?.dataPackageEnabled?.value),
            smsPackageEnabled: mapBooleanFilter(search.state?.smsPackageEnabled?.value),
            usagePackageTypeAssigned: mapBooleanFilter(search.state?.assigned?.value),
            usagePackageTypeAssignedToCatalogue: mapBooleanFilter(search.state?.inCatalogue?.value),
        });
    });

    return (
        <>
            <TabPanel>
                <VStack w={"100%"} gap={2}>
                    <SearchBar
                        defaultFilterInputId={"search"}
                        useSearch={search}
                        filterInputs={[
                            {
                                id: "search",
                                menuLabel: "Search",
                                inputComponent: (
                                    <SearchSingleInput
                                        registration={search.registerInput({ id: "search" })}
                                        placeholder={"Quick find..."}
                                    />
                                ),
                            },
                            {
                                id: "country",
                                menuLabel: "Country",
                                inputComponent: (
                                    <SearchSelectInput
                                        registration={search.registerInput({ id: "country" })}
                                        items={countries}
                                        autocompleteAbleMapping={value => `${value.name} (${value.iso2})`}
                                        displayMapping={value => <CountryInfo country={value} />}
                                    />
                                ),
                            },
                            {
                                id: "includeDeleted",
                                menuLabel: "Include Deleted",
                                inputComponent: (
                                    <SearchSelectInput
                                        registration={search.registerInput({ id: "includeDeleted" })}
                                        items={[true, false]}
                                        autocompleteAbleMapping={value => BooleanLabels(value)}
                                        displayMapping={value => BooleanLabels(value)}
                                    />
                                ),
                            },
                            {
                                id: "dataPackageEnabled",
                                menuLabel: "Data Enabled",
                                inputComponent: (
                                    <SearchSelectInput
                                        registration={search.registerInput({ id: "dataPackageEnabled" })}
                                        items={[true, false]}
                                        autocompleteAbleMapping={value => BooleanLabels(value)}
                                        displayMapping={value => BooleanLabels(value)}
                                    />
                                ),
                            },
                            {
                                id: "smsPackageEnabled",
                                menuLabel: "SMS Enabled",
                                inputComponent: (
                                    <SearchSelectInput
                                        registration={search.registerInput({ id: "smsPackageEnabled" })}
                                        items={[true, false]}
                                        autocompleteAbleMapping={value => BooleanLabels(value)}
                                        displayMapping={value => BooleanLabels(value)}
                                    />
                                ),
                            },
                            {
                                id: "inUse",
                                menuLabel: "Assigned to SIM",
                                inputComponent: (
                                    <SearchSelectInput
                                        registration={search.registerInput({ id: "assigned" })}
                                        items={[true, false]}
                                        autocompleteAbleMapping={value => BooleanLabels(value)}
                                        displayMapping={value => BooleanLabels(value)}
                                    />
                                ),
                            },
                            {
                                id: "inCatalogue",
                                menuLabel: "Assigned to Catalogue",
                                inputComponent: (
                                    <SearchSelectInput
                                        registration={search.registerInput({ id: "inCatalogue" })}
                                        items={[true, false]}
                                        autocompleteAbleMapping={value => BooleanLabels(value)}
                                        displayMapping={value => BooleanLabels(value)}
                                    />
                                ),
                            },
                        ]}
                    />
                    <UsagePackageTypeTable
                        usagePackageTypeQuery={usagePackageTypesQuery}
                        withRowClick={{
                            enableHoverStyle: true,
                            onRowClicked: navigate,
                        }}
                        withPagination={{
                            pageIndex: pageState.pageIndex,
                            pageSize: pageState.pageSize,
                            onPaginationChange: setPageState,
                            totalRowCount: usagePackageTypesQuery?.data?.pageProperties?.totalElements || 0,
                            totalPageCount: usagePackageTypesQuery?.data?.pageProperties?.totalPages || 0,
                        }}
                    />
                </VStack>
            </TabPanel>
        </>
    );
};

export default UsagePackageTypeListPanel;
