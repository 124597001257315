import React from "react";
import { LoadingSkeleton, PlaceholderText, QueryCell } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";

export interface ProductCellProps {
    productId?: string;
}

export const ProductCell = ({ productId }: ProductCellProps) => {
    const { productApi } = useApi();
    if (!productId) {
        return <PlaceholderText text={null} />;
    }

    return (
        <QueryCell
            loadingComponent={<LoadingSkeleton />}
            query={{
                queryFn: () => productApi.getProduct({ productId: productId }),
                queryKey: ["products", productId],
            }}
            render={result => <PlaceholderText text={result?.data?.name} />}
        />
    );
};
