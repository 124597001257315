import React from "react";
import { TabPanel } from "@chakra-ui/react";
import { SimCard, SmsCdr } from "../../../../api";
import { useQuery } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useApi } from "../../../../hooks/useApi";
import { CobiraTable, CountryInfo, DateTime, getCountry, usePageNavigation, usePagination } from "@cobira/ui-library";
import NetworkInfo from "../../../../components/NetworkInfo/NetworkInfo";
import { createColumnHelper } from "@tanstack/react-table";
import { SmsDirectionLabels } from "../../../../labels/SmsDirectionLabels";

interface SimCardSmsUsagePanelProps {
    query: UseQueryResult<SimCard>;
}

const smsUsageColumnHelper = createColumnHelper<SmsCdr>();
const COLUMNS = [
    smsUsageColumnHelper.accessor("imsi", {
        id: "imsi",
        header: "IMSI",
        cell: row => row.getValue(),
    }),
    smsUsageColumnHelper.accessor("plmn", {
        id: "plmn",
        header: "Network",
        cell: row => <NetworkInfo plmn={row.getValue()} />,
    }),
    smsUsageColumnHelper.accessor("countryCode", {
        id: "country",
        header: "Country",
        cell: row => <CountryInfo country={getCountry(row.getValue())} />,
    }),
    smsUsageColumnHelper.accessor("direction", {
        id: "direction",
        header: "Direction",
        cell: row => SmsDirectionLabels[row.getValue()],
    }),
    smsUsageColumnHelper.accessor("recordedAt", {
        id: "recordedAt",
        header: "Recorded At",
        cell: row => <DateTime value={row.getValue()} />,
    }),
];

const SimCardSmsUsagePanel = ({ query: { data: value } }: SimCardSmsUsagePanelProps) => {
    const { cdrApi } = useApi();
    const pageNavigation = usePageNavigation<SmsCdr>({ route: value => `/simcards/${value.icc}/cdr/sms/${value.id}` });
    const { pageState, setPageState } = usePagination({
        pageIndex: 0,
        pageSize: 20,
    });

    const { data: sms, isLoading: isLoadingDataSessions } = useQuery(
        ["simcards", value?.icc, "smscdr", pageState],
        () => {
            const now = new Date();
            const oneMonthAgo = new Date(now);
            oneMonthAgo.setMonth(now.getMonth() - 1);
            return cdrApi.getSmsCdr({
                icc: value?.icc || "",
                pageNumber: pageState.pageIndex,
                pageSize: pageState.pageSize,
                fromDate: oneMonthAgo,
                toDate: now,
            });
        },
        { enabled: value?.icc !== undefined },
    );

    return (
        <TabPanel w={"100%"}>
            <CobiraTable
                columns={COLUMNS}
                data={sms?.content || []}
                isLoading={isLoadingDataSessions}
                withPagination={{
                    pageSize: pageState.pageSize,
                    pageIndex: pageState.pageIndex,
                    onPaginationChange: setPageState,
                    totalRowCount: sms?.pageProperties?.totalElements || 0,
                    totalPageCount: sms?.pageProperties?.totalPages || 0,
                }}
                withRowClick={{
                    enableHoverStyle: true,
                    onRowClicked: pageNavigation.navigate,
                }}
            />
        </TabPanel>
    );
};

export default SimCardSmsUsagePanel;
