import { CountryInfo, DateTime, getCountry, Paper, PlaceholderText, TextColumn } from "@cobira/ui-library";
import { Grid, VStack } from "@chakra-ui/react";
import { NetworkCredentialTypeCell } from "../Cells/NetworkCredentialTypeCell/NetworkCredentialTypeCell";
import { SimConfigurationCell } from "../Cells/SimConfigurationCell/SimConfigurationCell";
import { CustomerCell } from "../Cells/CustomerCell/CustomerCell";
import { BillingGroupCell } from "../Cells/BillingGroupCell/BillingGroupCell";
import { ProductCell } from "../Cells/ProductCell/ProductCell";
import { CostPlanCell } from "../Cells/CostPlanCell/CostPlanCell";
import { RatePlanCell } from "../Cells/RatePlanCell/RatePlanCell";
import { CdrStateLabels } from "../../labels/CdrStateLabels";
import { CdrState, SmsCdr } from "../../api";
import { SmsDirectionLabels } from "../../labels/SmsDirectionLabels";
import { SmsOriginLabels } from "../../labels/SmsOriginLabels";
import NetworkInfo from "../NetworkInfo/NetworkInfo";
import React from "react";
import { UseQueryResult } from "@tanstack/react-query";

export interface SmsCdrDetailsProps {
    query: UseQueryResult<SmsCdr>;
}

const SmsCdrDetails = ({ query: { data: cdr, isLoading } }: SmsCdrDetailsProps) => {
    return (
        <VStack w={"100%"} align={"stretch"} gap={4}>
            <Paper header={"SIM Details"}>
                <Grid templateColumns={"auto auto auto auto"}>
                    <TextColumn heading={"ICC"} isLoading={isLoading}>
                        <PlaceholderText text={cdr?.icc} />
                    </TextColumn>
                    <TextColumn heading={"IMSI"} isLoading={isLoading}>
                        <PlaceholderText text={cdr?.imsi} />
                    </TextColumn>
                    <TextColumn heading={"Network Credential Type"} isLoading={isLoading}>
                        <NetworkCredentialTypeCell networkCredentialTypeId={cdr?.networkCredentialTypeId} />
                    </TextColumn>
                    <TextColumn heading={"SIM Configuration"} isLoading={isLoading}>
                        <SimConfigurationCell simConfigurationId={cdr?.simConfigurationId} />
                    </TextColumn>
                </Grid>
            </Paper>
            <Paper header={"Ownership"}>
                <Grid templateColumns={"auto auto auto"}>
                    <TextColumn heading={"Customer"} isLoading={isLoading}>
                        <CustomerCell customerId={cdr?.customerId} />
                    </TextColumn>
                    <TextColumn heading={"Billing Group"} isLoading={isLoading}>
                        <BillingGroupCell billingGroupId={cdr?.billingGroupId} />
                    </TextColumn>
                    <TextColumn heading={"Product"} isLoading={isLoading}>
                        <ProductCell productId={cdr?.productId} />
                    </TextColumn>
                </Grid>
            </Paper>
            <Paper header={"Usage Details"}>
                <Grid templateColumns={"auto auto auto"} rowGap={4}>
                    <TextColumn heading={"Cost Plan"} isLoading={isLoading}>
                        <CostPlanCell
                            costPlanId={cdr?.customerId}
                            networkCredentialTypeId={cdr?.networkCredentialTypeId}
                        />
                    </TextColumn>
                    <TextColumn heading={"Rate Plan"} isLoading={isLoading}>
                        <RatePlanCell ratePlanId={cdr?.ratePlanId} />
                    </TextColumn>
                    <TextColumn heading={"Cost"} isLoading={isLoading}>
                        <PlaceholderText
                            text={cdr?.cost?.toFixed(4)}
                            placeholderText={"Cost is missing, please correct the cost plan to properly rate"}
                        />
                    </TextColumn>
                    <TextColumn heading={"Status"} isLoading={isLoading}>
                        {CdrStateLabels[cdr?.status || CdrState.Unknown]}
                    </TextColumn>
                    <TextColumn heading={"Recorded At"} isLoading={isLoading}>
                        <DateTime value={cdr?.recordedAt} />
                    </TextColumn>
                </Grid>
            </Paper>
            <Paper header={"Network Details"}>
                <Grid templateColumns={"auto auto"} rowGap={4}>
                    <TextColumn heading={"SMS Direction"} isLoading={isLoading}>
                        {SmsDirectionLabels[cdr?.direction || "UNKNOWN"]}
                    </TextColumn>
                    <TextColumn heading={"Type"} isLoading={isLoading}>
                        {SmsOriginLabels[cdr?.origin || "UNKNOWN"]}
                    </TextColumn>
                    <TextColumn heading={"Network"} isLoading={isLoading}>
                        <NetworkInfo plmn={cdr?.plmn} />
                    </TextColumn>
                    <TextColumn heading={"Country"} isLoading={isLoading}>
                        <CountryInfo country={getCountry(cdr?.countryCode)} />
                    </TextColumn>
                </Grid>
            </Paper>
        </VStack>
    );
};

export default SmsCdrDetails;
