/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TelcoServiceType } from './TelcoServiceType';
import {
    TelcoServiceTypeFromJSON,
    TelcoServiceTypeFromJSONTyped,
    TelcoServiceTypeToJSON,
} from './TelcoServiceType';

/**
 * 
 * @export
 * @interface TelcoCost
 */
export interface TelcoCost {
    /**
     * 
     * @type {string}
     * @memberof TelcoCost
     */
    costPlanId: string;
    /**
     * 
     * @type {string}
     * @memberof TelcoCost
     */
    key: string;
    /**
     * 
     * @type {TelcoServiceType}
     * @memberof TelcoCost
     */
    serviceType: TelcoServiceType;
    /**
     * 
     * @type {number}
     * @memberof TelcoCost
     */
    pricePerUnit: number;
    /**
     * 
     * @type {string}
     * @memberof TelcoCost
     */
    unit: string;
}

/**
 * Check if a given object implements the TelcoCost interface.
 */
export function instanceOfTelcoCost(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "costPlanId" in value;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "serviceType" in value;
    isInstance = isInstance && "pricePerUnit" in value;
    isInstance = isInstance && "unit" in value;

    return isInstance;
}

export function TelcoCostFromJSON(json: any): TelcoCost {
    return TelcoCostFromJSONTyped(json, false);
}

export function TelcoCostFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelcoCost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'costPlanId': json['costPlanId'],
        'key': json['key'],
        'serviceType': TelcoServiceTypeFromJSON(json['serviceType']),
        'pricePerUnit': json['pricePerUnit'],
        'unit': json['unit'],
    };
}

export function TelcoCostToJSON(value?: TelcoCost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'costPlanId': value.costPlanId,
        'key': value.key,
        'serviceType': TelcoServiceTypeToJSON(value.serviceType),
        'pricePerUnit': value.pricePerUnit,
        'unit': value.unit,
    };
}

