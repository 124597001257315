/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CdrState = {
    Calculated: 'CALCULATED',
    ErrorRaised: 'ERROR_RAISED',
    Unknown: 'UNKNOWN'
} as const;
export type CdrState = typeof CdrState[keyof typeof CdrState];


export function CdrStateFromJSON(json: any): CdrState {
    return CdrStateFromJSONTyped(json, false);
}

export function CdrStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): CdrState {
    return json as CdrState;
}

export function CdrStateToJSON(value?: CdrState | null): any {
    return value as any;
}

