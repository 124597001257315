/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { YearMonth } from './YearMonth';
import {
    YearMonthFromJSON,
    YearMonthFromJSONTyped,
    YearMonthToJSON,
} from './YearMonth';

/**
 * 
 * @export
 * @interface UpdateCostPlan
 */
export interface UpdateCostPlan {
    /**
     * 
     * @type {string}
     * @memberof UpdateCostPlan
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCostPlan
     */
    description?: string | null;
    /**
     * 
     * @type {YearMonth}
     * @memberof UpdateCostPlan
     */
    validAt?: YearMonth;
    /**
     * 
     * @type {number}
     * @memberof UpdateCostPlan
     */
    conversionFactor?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCostPlan
     */
    overwritable?: boolean | null;
    /**
     * 
     * @type {Currency}
     * @memberof UpdateCostPlan
     */
    currency?: Currency;
}

/**
 * Check if a given object implements the UpdateCostPlan interface.
 */
export function instanceOfUpdateCostPlan(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateCostPlanFromJSON(json: any): UpdateCostPlan {
    return UpdateCostPlanFromJSONTyped(json, false);
}

export function UpdateCostPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCostPlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'validAt': !exists(json, 'validAt') ? undefined : YearMonthFromJSON(json['validAt']),
        'conversionFactor': !exists(json, 'conversionFactor') ? undefined : json['conversionFactor'],
        'overwritable': !exists(json, 'overwritable') ? undefined : json['overwritable'],
        'currency': !exists(json, 'currency') ? undefined : CurrencyFromJSON(json['currency']),
    };
}

export function UpdateCostPlanToJSON(value?: UpdateCostPlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'validAt': YearMonthToJSON(value.validAt),
        'conversionFactor': value.conversionFactor,
        'overwritable': value.overwritable,
        'currency': CurrencyToJSON(value.currency),
    };
}

