import { useParams } from "react-router";
import { PageBackButton, PageLayout, PageTitle } from "@cobira/ui-library";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../../../hooks/useApi";
import SmsCdrDetails from "../../../../components/Cdr/SmsCdrDetails";

const SimCardSmsCdrDetailPage = () => {
    const { cdrId, icc } = useParams();
    const { cdrApi } = useApi();

    const smsCdrQuery = useQuery(["cdr", "sms", cdrId], () => cdrApi.getSmsCdrRecord({ cdrId: cdrId || "" }), {
        enabled: !!cdrId,
    });
    return (
        <PageLayout
            title={<PageTitle title={"CDR Details"} />}
            navigation={<PageBackButton text={"Back to SIM Card"} path={`/simcards/${icc}`} />}
        >
            <SmsCdrDetails query={smsCdrQuery} />
        </PageLayout>
    );
};

export default SimCardSmsCdrDetailPage;
