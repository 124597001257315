import React from "react";
import { usePageNavigation, usePagination } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { NetworkCredentialType } from "../../../api";
import { useQuery } from "@tanstack/react-query";
import { TabPanel, VStack } from "@chakra-ui/react";
import NetworkCredentialTypeTable from "../../../components/NetworkCredentialTypeTable/NetworkCredentialTypeTable";

const NetworkCredentialTypeListPanel = () => {
    const { productApi } = useApi();
    const { pageState, setPageState } = usePagination({
        pageIndex: 0,
        pageSize: 20,
    });

    const { navigate } = usePageNavigation<NetworkCredentialType>({
        route: value => `/networkcredentialtypes/${value.id}`,
    });

    const networkCredentialTypeQuery = useQuery(["networkcredentialtypes", pageState], () => {
        return productApi.getNetworkCredentialTypes({
            pageNumber: pageState.pageIndex,
            pageSize: pageState.pageSize,
        });
    });

    return (
        <TabPanel w={"100%"}>
            <VStack w={"100%"} gap={2}>
                <NetworkCredentialTypeTable
                    networkCredentialTypeQuery={networkCredentialTypeQuery}
                    pagination={{
                        pageIndex: pageState.pageIndex,
                        pageSize: pageState.pageSize,
                        onPaginationChange: setPageState,
                        totalRowCount: networkCredentialTypeQuery?.data?.pageProperties?.totalElements || 0,
                        totalPageCount: networkCredentialTypeQuery?.data?.pageProperties?.totalPages || 0,
                    }}
                    withRowClick={{
                        enableHoverStyle: true,
                        onRowClicked: navigate,
                    }}
                />
            </VStack>
        </TabPanel>
    );
};

export default NetworkCredentialTypeListPanel;
