import React, { useState } from "react";
import { ChevronDownIcon, CobiraTabs, PageBackButton, PageLayout, PageTitle } from "@cobira/ui-library";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import CustomerDetailPanel from "./CustomerDetailPanel/CustomerDetailPanel";
import CustomerBillingGroupsPanel from "./CustomerBillingGroupsPanel/CustomerBillingGroupsPanel";
import CustomerContactsPanel from "./CustomerContactsPanel/CustomerContactsPanel";
import CustomerPackageCataloguePanel from "./CustomerPackageCataloguePanel/CustomerPackageCataloguePanel";
import CustomerRecurringFeesPanel from "./CustomerRecurringFeesPanel/CustomerRecurringFeesPanel";
import CreateBillingGroupModal from "../../../components/Modal/CreateBillingGroupModal/CreateBillingGroupModal";
import CreateCustomerContactModal from "../../../components/Modal/CreateCustomerContactModal/CreateCustomerContactModal";
import AddPackageToCatalogueModal from "../../../components/Modal/AddPackageToCatalogueModal/AddPackageToCatalogueModal";
import CreateCustomerRecurringFeeModal from "../../../components/Modal/CreateCustomerRecurringFeeModal/CreateCustomerRecurringFeeModal";
import UpdateCustomerModal from "../../../components/Modal/UpdateCustomerModal/UpdateCustomerModal";
import CustomerInvoicesPanel from "./CustomerInvoicesPanel/CustomerInvoicesPanel";
import { tab } from "../../../utils/tabUtils";

type CustomerOperations =
    | "create-billing-group"
    | "create-contact"
    | "create-recurring-fee"
    | "update-details"
    | "add-package";

const CustomerDetailPage = () => {
    const { customerApi } = useApi();
    const { customerId } = useParams();
    const [chosenOperation, setChosenOperation] = useState<CustomerOperations | null>(null);
    const customerQuery = useQuery(
        ["customers", customerId],
        () => customerApi.getCustomer({ customerId: customerId || "" }),
        {
            enabled: customerId != undefined,
        },
    );
    const customerDetailTabs = [
        tab("Details", <CustomerDetailPanel query={customerQuery} />),
        tab("Billing Groups", <CustomerBillingGroupsPanel query={customerQuery} />),
        tab("Team", <CustomerContactsPanel query={customerQuery} />),
        tab("Bundles", <CustomerPackageCataloguePanel query={customerQuery} />),
        tab("Fees", <CustomerRecurringFeesPanel query={customerQuery} />),
        tab("Invoices", <CustomerInvoicesPanel query={customerQuery} />),
    ];

    return (
        <PageLayout
            title={<PageTitle title={customerQuery?.data?.customerName || "Customer"} />}
            navigation={<PageBackButton text={"Back to customers"} path={"/customers"} />}
            pageContext={
                <Menu>
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                        Manage
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => setChosenOperation("update-details")}>Update</MenuItem>
                        <MenuItem onClick={() => setChosenOperation("create-billing-group")}>
                            Create Billing Group
                        </MenuItem>
                        <MenuItem onClick={() => setChosenOperation("create-contact")}>Create Contact</MenuItem>
                        <MenuItem onClick={() => setChosenOperation("add-package")}>Add Package</MenuItem>
                        <MenuItem onClick={() => setChosenOperation("create-recurring-fee")}>
                            Create Recurring Fee
                        </MenuItem>
                    </MenuList>
                </Menu>
            }
        >
            <CobiraTabs tabs={customerDetailTabs} />
            <CreateBillingGroupModal
                customer={customerQuery.data}
                isOpen={chosenOperation === "create-billing-group"}
                onClose={() => setChosenOperation(null)}
            />
            <CreateCustomerContactModal
                customer={customerQuery.data}
                isOpen={chosenOperation === "create-contact"}
                onClose={() => setChosenOperation(null)}
            />
            <AddPackageToCatalogueModal
                customer={customerQuery.data}
                isOpen={chosenOperation === "add-package"}
                onClose={() => setChosenOperation(null)}
            />
            <CreateCustomerRecurringFeeModal
                customer={customerQuery.data}
                isOpen={chosenOperation === "create-recurring-fee"}
                onClose={() => setChosenOperation(null)}
            />
            <UpdateCustomerModal
                customer={customerQuery.data}
                isOpen={chosenOperation === "update-details"}
                onClose={() => setChosenOperation(null)}
            />
        </PageLayout>
    );
};

export default CustomerDetailPage;
