import React from "react";
import { Grid, TabPanel, Text, VStack } from "@chakra-ui/react";
import { CostPlan } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { DateTime, Month, monthToInt, Paper, PlaceholderText, TextColumn } from "@cobira/ui-library";
import { CurrencyLabels } from "../../../../labels/CurrencyLabels";

export interface TenantNetworkCredentialTypeCostPlanDetailsPanelProps {
    query: UseQueryResult<CostPlan>;
    tenantId?: string;
}

const TenantNetworkCredentialTypeCostPlanDetailsPanel = ({
    query: { data: value, isLoading },
}: TenantNetworkCredentialTypeCostPlanDetailsPanelProps) => {
    return (
        <TabPanel w={"100%"}>
            <VStack w={"100%"} align={"stretch"} gap={6}>
                <Paper header={"Details"}>
                    <Grid templateColumns="auto auto" gap="4">
                        <TextColumn heading="Name" grid isLoading={isLoading}>
                            {value?.name}
                        </TextColumn>
                        <TextColumn heading="Description" grid isLoading={isLoading}>
                            <PlaceholderText text={value?.description} />
                        </TextColumn>
                        <TextColumn heading={"Currency"} grid isLoading={isLoading}>
                            {CurrencyLabels[value?.currency || "UNDEFINED"]}
                        </TextColumn>
                        <TextColumn heading={"Locked for edits"} grid isLoading={isLoading}>
                            <Text>{value?.overwritable ? "Yes" : "No"}</Text>
                        </TextColumn>
                        <TextColumn heading={"Applies from"} grid isLoading={isLoading}>
                            <DateTime
                                value={
                                    value?.validAt
                                        ? new Date(value?.validAt.year, monthToInt(value?.validAt.month as Month))
                                        : null
                                }
                                format={"MMMM, yyyy"}
                            />
                        </TextColumn>
                    </Grid>
                </Paper>
            </VStack>
        </TabPanel>
    );
};

export default TenantNetworkCredentialTypeCostPlanDetailsPanel;
